import * as Sentry from "@sentry/react";

import 'bootstrap/dist/css/bootstrap.css';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import {
    createBrowserRouter,
    RouterProvider,
} from "react-router-dom";
// import './index.css';
import reportWebVitals from './reportWebVitals';

import DashboardV2 from './routes/dashboard_v2';
import ReportsList from './routes/reports_list';
import ReportPage from './routes/report_page';
import ExpertSteps from './routes/expert_steps';
import Runbooks from './routes/runbooks';

// import Dashboard from './routes/dashboard';
// import Sandbox from './routes/sandbox'
// import IssueViewer from './routes/issue_viewer';
// import IssueAnalysisViewer from './routes/issue_analysis_viewer';
// import DatasetViewer from './routes/dataset_viewer';
// import CreateIncident from './routes/create_incident';
// import IssueDetailsPage from './routes/issue_details';
// import IssuesList from './routes/issue_list';

import Configuration from './routes/configuration';
import Landing from './routes/landing';
import Setup from './routes/setup';
import Settings from './routes/settings';
import Invitation from './routes/invitation';
import AdminPage from './routes/admin/admin';
import SlackRedirect from './routes/slack_redirect';
import OktaRedirect from './routes/okta_redirect';
import SsoLogin from './routes/sso_login';
import ConfluenceRedirect from './routes/confluence_redirect';
import GithubSetupRedirect from './routes/github_setup_redirect';
import VerifyEmail from './routes/verify_email';
import ResetPassword from './routes/reset_password';
import { GoogleOAuthProvider } from '@react-oauth/google';

import Monitor from './routes/monitor';

import './components/demo.css';

import { Navbar, Container, Nav, NavDropdown } from 'react-bootstrap';
import { PersonCircle } from 'react-bootstrap-icons';
import { IoSettings } from 'react-icons/io5';  // Add this import

import { logout, getIdTokenFromCookie, getLoginInfoFromCookie } from './routes/utils';  // Add this import

if (process.env.REACT_APP_ENVIRONMENT) {
  Sentry.init({
    dsn: "https://27f1492c3e2306cf911c611a516f242f@o4508031859097600.ingest.us.sentry.io/4508031861063680",
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
    ],
    // Tracing
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [/^https:\/\/*\.relvy\.ai\//],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    environment: process.env.REACT_APP_ENVIRONMENT,
  });
}

const Logout = () => {
  useEffect(() => {
    logout();
  }, []);

  return null;
};

function NavbarComponent() {
  const handleLogout = (event) => {
    event.preventDefault();
    logout();
  };

  const [slackButtonLink, setSlackButtonLink] = useState(null);

  useEffect(() => {
    const loginCallback = (data) => {
      if (data.organization && data.organization.slack){
        if (!data.organization.slack.installed && data.organization.slack.show_link){
          const redirect_uri = encodeURIComponent(data.organization.slack.hostname + '/slack/redirect');
          const client_id = process.env.REACT_APP_SLACK_CLIENT_ID;
          setSlackButtonLink(`https://slack.com/oauth/v2/authorize?scope=app_mentions:read,channels:history,channels:read,chat:write,commands&user_scope=&redirect_uri=${redirect_uri}&client_id=${client_id}`);
        }
      }
    };

    const loginFailureCallback = () => {
    };
    getLoginInfoFromCookie(loginCallback, loginFailureCallback);
  }, []);

  const slackButtonStyle = {
    alignItems: 'center',
    color: '#000',
    backgroundColor: '#fff',
    border: '1px solid #ddd',
    borderRadius: '4px',
    display: 'inline-flex',
    fontFamily: 'Lato, sans-serif',
    fontSize: '16px',
    fontWeight: '600',
    height: '48px',
    justifyContent: 'center',
    textDecoration: 'none',
    width: '120px',
  };

  return (
    <Navbar expand="lg" fixed="top" className="shadow-sm" bg="white">
      <Container>
        <Navbar.Brand href="/" rel="noopener noreferrer" className="fw-bold">
          <img alt="Relvy" src="/RelvyAI_FullLogo_Cropped.png" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="navbarResponsive" />
        <Navbar.Collapse id="navbarResponsive">
          <Nav className="ms-auto">
            {/* {slackButtonLink && (
              <a target="_blank" rel="noreferrer" className="mx-3" href={slackButtonLink} style={slackButtonStyle}><svg xmlns="http://www.w3.org/2000/svg" style={{height: '20px', width: '20px', marginRight:'12px'}} viewBox="0 0 122.8 122.8"><path d="M25.8 77.6c0 7.1-5.8 12.9-12.9 12.9S0 84.7 0 77.6s5.8-12.9 12.9-12.9h12.9v12.9zm6.5 0c0-7.1 5.8-12.9 12.9-12.9s12.9 5.8 12.9 12.9v32.3c0 7.1-5.8 12.9-12.9 12.9s-12.9-5.8-12.9-12.9V77.6z" fill="#e01e5a"></path><path d="M45.2 25.8c-7.1 0-12.9-5.8-12.9-12.9S38.1 0 45.2 0s12.9 5.8 12.9 12.9v12.9H45.2zm0 6.5c7.1 0 12.9 5.8 12.9 12.9s-5.8 12.9-12.9 12.9H12.9C5.8 58.1 0 52.3 0 45.2s5.8-12.9 12.9-12.9h32.3z" fill="#36c5f0"></path><path d="M97 45.2c0-7.1 5.8-12.9 12.9-12.9s12.9 5.8 12.9 12.9-5.8 12.9-12.9 12.9H97V45.2zm-6.5 0c0 7.1-5.8 12.9-12.9 12.9s-12.9-5.8-12.9-12.9V12.9C64.7 5.8 70.5 0 77.6 0s12.9 5.8 12.9 12.9v32.3z" fill="#2eb67d"></path><path d="M77.6 97c7.1 0 12.9 5.8 12.9 12.9s-5.8 12.9-12.9 12.9-12.9-5.8-12.9-12.9V97h12.9zm0-6.5c-7.1 0-12.9-5.8-12.9-12.9s5.8-12.9 12.9-12.9h32.3c7.1 0 12.9 5.8 12.9 12.9s-5.8 12.9-12.9 12.9H77.6z" fill="#ecb22e"></path>
              </svg>Slack</a>
              )} */}
            <Button href="https://share.hsforms.com/1R29gPNRURmKe6vLR_0Q9vQrhaij" target="_blank" rel="noopener noreferrer" className="me-lg-3 outline-custom-btn d-flex align-items-center justify-content-center">
              Get in Touch
            </Button>
            {getIdTokenFromCookie() && (
              <NavDropdown title={<IoSettings size={28} color="#5935c9" />} id="user-dropdown" align="end">
                <NavDropdown.Item href="/account">Account</NavDropdown.Item>
                <NavDropdown.Item href="https://www.relvy.ai/relvy-tos" target="_blank" rel="noopener noreferrer">Terms and Policies</NavDropdown.Item>
                <NavDropdown.Item onClick={handleLogout}>Log Out</NavDropdown.Item>
              </NavDropdown>
            )}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

const router = createBrowserRouter([
  {
    path: "/",
    element: <Landing />,
  },
  {
    path: "/dashboard",
    element: <DashboardV2 />,
  },
  {
    path: "/reports",
    element: <ReportsList />,
  },
  {
    path: "/reports/:reportId",
    element: <ReportPage />,
  },
  // {
  //   path: "/dashboard",
  //   element: <Dashboard />,
  // },
  // {
  //   path: "/issue_list",
  //   element: <IssuesList />,
  // },
  {
    path: "/issues/:issueId/expert_steps",
    element: <ExpertSteps />,
  },
  // {
  //   path: "/sandbox/create",
  //   element: <CreateIncident />,
  // },
  // {
  //   path: "/admin/dataset_viewer",
  //   element: <DatasetViewer />,
  // },
  // {
  //   path: "/admin/issue_viewer",
  //   element: <IssueViewer />,
  // },
  // {
  //   path: "/admin/issue_analysis_viewer",
  //   element: <IssueAnalysisViewer />,
  // },
  // {
  //   path: "/issue_details",
  //   element: <IssueDetailsPage />,
  // },
  {
    path: "/configure",
    element: <Configuration />,
  },
  {
    path: "/setup",
    element: <Setup />,
  },
  {
    path: "/account",
    element: <Settings />,
  },
  {
    path: "/relvyadmin",
    element: <AdminPage />,
  },
  {
    path: "/invitation",
    element: <Invitation />,
  },
  {
    path: '/slack/redirect',
    element: <SlackRedirect />,
  },
  {
    path: "/o/:orgId/okta/redirect",
    element: <OktaRedirect />,
  },
  {
    path: "/login/sso",
    element: <SsoLogin />,
  },
  {
    path: "/logout",
    element: <Logout />,
  },
  {
    path: '/confluence/redirect',
    element: <ConfluenceRedirect />,
  },
  {
    path: '/github/setup/redirect',
    element: <GithubSetupRedirect />,
  },
  {
    path: '/verify_email',
    element: <VerifyEmail />,
  },
  {
    path: '/reset_password',
    element: <ResetPassword />,
  },
  {
    path: '/monitor',
    element: <Monitor />,
  },
  {
    path: "/runbooks",
    element: <Runbooks />,
  }
]);

function RelvyApp() {
  return (
    <>
      <NavbarComponent />
      <section style={{ paddingTop: '4rem' }}>
        <RouterProvider router={router} />
      </section>
    </>
  );
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <GoogleOAuthProvider clientId="415844705945-j6o5aomfu4jmqgg86d2ehg4p4905u5ia.apps.googleusercontent.com">
      <RelvyApp />
    </GoogleOAuthProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
