import React, { useState, useEffect } from 'react';
import { Form, Button, Modal, InputGroup, Card, Badge, Spinner } from 'react-bootstrap';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { Search } from 'react-bootstrap-icons';
import { IoAddCircle, IoRefresh, IoSearch, IoTrash, IoPencil } from 'react-icons/io5';
import ReactMarkdown from 'react-markdown';

import Sidebar from '../components/Sidebar';
import { makeAuthenticatedRequest, formatDateTime } from './utils';
import withAuth from '../components/withAuth';
import '../components/demo.css';

// Export all the reusable components
export { RunbookDetailView, RunbookForm, RunbookListItem, RunbookListAndDetailView, DeleteConfirmationModal };

const RunbookDetailView = ({ runbook, onEdit, onDelete, showDeleteButton = true }) => {
  if (!runbook) {
    return (
      <div className="text-center py-5">
        <p>Select a runbook from the list or create a new one</p>
      </div>
    );
  }

  const runbookTypeLabels = {
    'planner': 'General Planning',
    'log_analysis_agent': 'Log Analysis',
    'event_analysis_agent': 'Event Analysis',
    'dashboard_analysis_agent': 'Dashboard Analysis',
    'metric_analysis_agent': 'Metric Analysis',
    'workflow': 'Workflow' // For backward compatibility
  };

  const renderTagBadges = (tags) => {
    if (!tags || tags.length === 0) return null;

    return (
      <div className="d-flex flex-wrap gap-2 mt-3">
        {tags.map((tag, index) => (
          <Badge key={index} bg="light" text="dark" className="border py-2 px-2">
            {tag}
          </Badge>
        ))}
      </div>
    );
  };

  return (
    <div className="runbook-detail">
      <div className="d-flex justify-content-between align-items-center mb-3">
        <div>
          <h4>{runbook.title || `${runbook.symptom_description.substring(0, 80)}...`}</h4>
          <Badge bg="secondary" className="me-2">
            {runbookTypeLabels[runbook.type] || runbook.type}
          </Badge>
        </div>
        <div>
          <Button
            variant="outline-primary"
            size="sm"
            className="me-2"
            onClick={() => onEdit(runbook)}
          >
            <IoPencil /> Edit
          </Button>
          {showDeleteButton && (
            <Button
              variant="outline-danger"
              size="sm"
              onClick={() => onDelete(runbook.id)}
            >
              <IoTrash /> Delete
            </Button>
          )}
        </div>
      </div>

      <Card className="mb-4">
        <Card.Header className="bg-light">
          <strong>Symptoms</strong>
        </Card.Header>
        <Card.Body className="font-size-085">
          <p>{runbook.symptom_description}</p>
          {renderTagBadges(runbook.runbook_data?.tags)}
        </Card.Body>
      </Card>

      <Card>
        <Card.Header className="bg-light">
          <strong>Runbook Instructions</strong>
        </Card.Header>
        <Card.Body className="font-size-085">
          <div className="markdown-content">
            <ReactMarkdown breaks={true}>{runbook.runbook_data?.content || ''}</ReactMarkdown>
          </div>
        </Card.Body>
      </Card>
    </div>
  );
};

const RunbookForm = ({
  runbook = null,
  onSubmit,
  onCancel,
  isSubmitting
}) => {
  const [title, setTitle] = useState(runbook?.title || '');
  const [symptomDescription, setSymptomDescription] = useState(runbook?.symptom_description || '');
  const [runbookContent, setRunbookContent] = useState(runbook?.runbook_data?.content || '');
  const [tags, setTags] = useState(runbook?.runbook_data?.tags?.join(', ') || '');
  const [runbookType, setRunbookType] = useState(runbook?.type || 'planner');

  const runbookTypes = [
    { value: 'planner', label: 'General Planning' },
    { value: 'log_analysis_agent', label: 'Log Analysis' },
    { value: 'event_analysis_agent', label: 'Event Analysis' },
    { value: 'dashboard_analysis_agent', label: 'Dashboard Analysis' },
    { value: 'metric_analysis_agent', label: 'Metric Analysis' }
  ];

  const handleSubmit = (e) => {
    e.preventDefault();

    const tagArray = tags
      .split(',')
      .map(tag => tag.trim())
      .filter(tag => tag.length > 0);

    onSubmit({
      title: title,
      symptom_description: symptomDescription,
      runbook_content: runbookContent,
      type: runbookType,
      tags: tagArray,
      id: runbook?.id
    });
  };

  const labelStyle = {
    fontSize: '0.9rem',
    fontWeight: '600',
    color: '#495057',
    marginBottom: '0.5rem',
    display: 'flex',
    alignItems: 'center',
    letterSpacing: '0.03rem'
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Form.Group className="mb-3">
        <Form.Label style={labelStyle}>Title</Form.Label>
        <Form.Control className="font-size-085"
          type="text"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          required
          placeholder="Enter a title for this runbook"
        />
      </Form.Group>

      <Form.Group className="mb-3">
        <Form.Label style={labelStyle}>Runbook Type</Form.Label>
        <Form.Select className="font-size-085"
          value={runbookType}
          onChange={(e) => setRunbookType(e.target.value)}
          required
        >
          {runbookTypes.map(type => (
            <option key={type.value} value={type.value}>{type.label}</option>
          ))}
        </Form.Select>
      </Form.Group>

      <Form.Group className="mb-3">
        <Form.Label style={labelStyle}>Symptom Description</Form.Label>
        <Form.Control className="font-size-085"
          as="textarea"
          rows={2}
          value={symptomDescription}
          onChange={(e) => setSymptomDescription(e.target.value)}
          required
          placeholder="When should this runbook be used? Symptoms, etc."
        />
      </Form.Group>

      <Form.Group className="mb-3">
        <Form.Label style={labelStyle}>Runbook Instructions</Form.Label>
        <Form.Control className="font-size-085"
          as="textarea"
          rows={10}
          value={runbookContent}
          onChange={(e) => setRunbookContent(e.target.value)}
          required
          placeholder="# Analysis direction&#10;1. Check events with label prod-db-outage...&#10;2. Check server logs for 500 errors...&#10;3. Check kafka dashboard for errors..."
        />
      </Form.Group>

      <Form.Group className="mb-3">
        <Form.Label style={labelStyle}>
          Tags (comma separated)
          <span className="ms-2 text-muted" style={{fontSize: '0.8rem', fontWeight: 'normal'}}>[Optional]</span>
        </Form.Label>
        <Form.Control className="font-size-085"
          type="text"
          value={tags}
          onChange={(e) => setTags(e.target.value)}
          placeholder="e.g., database, performance, outage"
        />
      </Form.Group>

      <div className="d-flex justify-content-end">
        <Button variant="secondary" onClick={onCancel} className="me-2" disabled={isSubmitting}>
          Cancel
        </Button>
        <Button type="submit" variant="primary" className="custom-btn" disabled={isSubmitting}>
          {isSubmitting ? (
            <>
              <Spinner animation="border" size="sm" className="me-2" />
              {runbook ? 'Updating...' : 'Creating...'}
            </>
          ) : (
            runbook ? 'Update Runbook' : 'Create Runbook'
          )}
        </Button>
      </div>
    </Form>
  );
};

const RunbookListItem = ({ runbook, isSelected, onClick }) => {
  return (
    <Card
      onClick={onClick}
      className={`${isSelected ? 'border-primary' : ''} mb-2`}
      style={{
        cursor: isSelected ? "default" : "pointer",
        transition: 'all 0.2s ease-in-out'
      }}
    >
      <Card.Body className="py-2 px-3">
        <div className="d-flex flex-column">
          <span
            className={`fw-medium ${isSelected ? 'text-primary' : ''}`}
            style={{ fontSize: '0.9rem' }}
          >
            {runbook.title || runbook.symptom_description.substring(0, 80)}
            {!runbook.title && runbook.symptom_description.length > 80 ? '...' : ''}
          </span>
          <div className="d-flex justify-content-between align-items-center">
            <small className="text-muted" style={{ fontSize: '0.75rem' }}>
              {formatDateTime(runbook.updated_at || runbook.created_at)}
            </small>
            <div className="d-flex flex-wrap gap-1">
              {runbook.runbook_data?.tags?.slice(0, 2).map((tag, index) => (
                <Badge key={index} bg="light" text="dark" style={{ fontSize: '0.6rem' }} className="border">
                  {tag}
                </Badge>
              ))}
              {runbook.runbook_data?.tags?.length > 2 && (
                <Badge bg="light" text="dark" style={{ fontSize: '0.6rem' }} className="border">
                  +{runbook.runbook_data.tags.length - 2}
                </Badge>
              )}
            </div>
          </div>
        </div>
      </Card.Body>
    </Card>
  );
};

const DeleteConfirmationModal = ({ show, onHide, onConfirm, isDeleting }) => {
  return (
    <Modal show={show} onHide={onHide} centered>
      <Modal.Header closeButton>
        <Modal.Title>Confirm Delete</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        Are you sure you want to delete this runbook? This action cannot be undone.
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide} disabled={isDeleting}>
          Cancel
        </Button>
        <Button variant="danger" onClick={onConfirm} disabled={isDeleting}>
          {isDeleting ? (
            <>
              <Spinner animation="border" size="sm" className="me-2" />
              Deleting...
            </>
          ) : (
            'Delete'
          )}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

// Update the RunbookListAndDetailView component to be more self-contained
const RunbookListAndDetailView = ({
  runbooks = [],
  clientSystemId,
  initialSelectedIndex = null,
  onRunbookChange = () => {},  // Callback when a runbook is created, updated or deleted
  showDeleteButton = true,
  showCreateButton = true
}) => {
  const [selectedRunbookIndex, setSelectedRunbookIndex] = useState(initialSelectedIndex);
  const [isCreating, setIsCreating] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [editingRunbook, setEditingRunbook] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteRunbookId, setDeleteRunbookId] = useState(null);
  const [isDeleting, setIsDeleting] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [showSearch, setShowSearch] = useState(false);
  const [error, setError] = useState(null);

  // Set the initially selected index when runbooks change
  useEffect(() => {
    if (initialSelectedIndex !== null && runbooks.length > 0) {
      setSelectedRunbookIndex(initialSelectedIndex);
    }
  }, [initialSelectedIndex, runbooks]);

  const toggleSearch = () => {
    setShowSearch(!showSearch);
  };

  const getFilteredRunbooks = () => {
    if (!searchTerm) return runbooks.filter(Boolean);

    return runbooks.filter(runbook => {
      if (!runbook) return false;

      const searchLower = searchTerm.toLowerCase();
      const titleMatch = runbook.title?.toLowerCase().includes(searchLower);
      const symptomMatch = runbook.symptom_description?.toLowerCase().includes(searchLower);
      const contentMatch = runbook.runbook_data?.content?.toLowerCase().includes(searchLower);
      const tagMatch = runbook.runbook_data?.tags?.some(tag =>
        tag.toLowerCase().includes(searchLower)
      );

      return titleMatch || symptomMatch || contentMatch || tagMatch;
    });
  };

  const handleRunbookSelect = (index) => {
    setSelectedRunbookIndex(index);
    setIsCreating(false);
    setIsEditing(false);
  };

  const handleCreateNew = () => {
    setIsCreating(true);
    setIsEditing(false);
    setEditingRunbook(null);
  };

  const handleEdit = (runbook) => {
    setEditingRunbook(runbook);
    setIsEditing(true);
    setIsCreating(false);
  };

  const handleCancelForm = () => {
    setIsCreating(false);
    setIsEditing(false);
    setEditingRunbook(null);
  };

  const handleDeleteClick = (runbookId) => {
    if (showDeleteButton) {
      setDeleteRunbookId(runbookId);
      setShowDeleteModal(true);
    }
  };

  const handleConfirmDelete = () => {
    if (!deleteRunbookId) return;

    setIsDeleting(true);
    setError(null);

    makeAuthenticatedRequest(`/api/runbooks/${deleteRunbookId}`, {
      method: 'DELETE'
    })
      .then(response => {
        if (response.ok) {
          // Notify parent about the deletion
          onRunbookChange('delete', deleteRunbookId);
          setSelectedRunbookIndex(null);
        } else {
          throw new Error(`Server returned ${response.status}: ${response.statusText}`);
        }
      })
      .catch(error => {
        console.error('Error deleting runbook:', error);
        setError('Failed to delete runbook. Please try again.');
      })
      .finally(() => {
        setShowDeleteModal(false);
        setDeleteRunbookId(null);
        setIsDeleting(false);
      });
  };

  const handleSubmitRunbook = (runbookData) => {
    setIsSubmitting(true);
    setError(null);

    const isUpdate = !!runbookData.id;
    const endpoint = isUpdate
      ? `/api/runbooks/${runbookData.id}`
      : '/api/runbooks';

    const method = isUpdate ? 'PUT' : 'POST';

    const requestBody = {
      title: runbookData.title,
      symptom_description: runbookData.symptom_description,
      runbook_content: runbookData.runbook_content,
      type: runbookData.type,
      tags: runbookData.tags,
      client_system_id: clientSystemId
    };

    makeAuthenticatedRequest(endpoint, {
      method: method,
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(requestBody)
    })
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error(`Server returned ${response.status}: ${response.statusText}`);
        }
      })
      .then(data => {
        // Notify parent about the change
        onRunbookChange(isUpdate ? 'update' : 'create', data.runbook);
        setIsCreating(false);
        setIsEditing(false);
        setEditingRunbook(null);
      })
      .catch(error => {
        console.error('Error saving runbook:', error);
        setError('Failed to save runbook. Please try again.');
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  return (
    <div className="row">
      {/* Left panel - Runbook List */}
      <div className="col-md-3 position-sticky" style={{
        top: '64px',
        height: 'calc(100vh - 64px)',
        overflowY: 'auto',
        paddingTop: '1rem'
      }}>
        <div className="card">
          <div className="card-body">
            <div className="d-flex flex-column">
              <div className="d-flex justify-content-between align-items-center mb-2 bg-light p-2">
                <h5 className="mb-0">Runbooks</h5>
                <div className="d-flex justify-content-between align-items-center">
                  <Button
                    variant="link"
                    className="p-0 d-flex align-items-center justify-content-center"
                    style={{ width: '32px', height: '32px' }}
                    onClick={toggleSearch}
                  >
                    <IoSearch size={20} color="grey" />
                  </Button>

                  {showCreateButton && (
                    <Button
                      variant="link"
                      className="p-0 d-flex align-items-center justify-content-center"
                      style={{ width: '32px', height: '32px' }}
                      onClick={handleCreateNew}
                    >
                      <IoAddCircle size={28} color="green" />
                    </Button>
                  )}
                </div>
              </div>
            </div>

            {showSearch && (
              <div className="mb-3">
                <InputGroup>
                  <InputGroup.Text><Search /></InputGroup.Text>
                  <Form.Control
                    type="text"
                    placeholder="Search runbooks..."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                  />
                </InputGroup>
              </div>
            )}

            {error && (
              <div className="alert alert-danger" role="alert">
                {error}
              </div>
            )}

            {isLoading ? (
              <div className="text-center py-4">
                <Spinner animation="border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
              </div>
            ) : (
              <>
                {getFilteredRunbooks().length === 0 ? (
                  <div className="text-center py-4">
                    <p>No runbooks found</p>
                    {showCreateButton && (
                      <Button
                        variant="outline-primary"
                        onClick={handleCreateNew}
                        className="outline-custom-btn"
                      >
                        Create your first runbook
                      </Button>
                    )}
                  </div>
                ) : (
                  <div className="runbook-list">
                    {getFilteredRunbooks().map((runbook, index) => (
                      <RunbookListItem
                        key={runbook?.id || index}
                        runbook={runbook}
                        isSelected={selectedRunbookIndex === index}
                        onClick={() => handleRunbookSelect(index)}
                      />
                    ))}
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </div>

      {/* Right panel - Runbook Details */}
      <div className="col-md-9" style={{
        paddingTop: '1rem',
        maxWidth: '100%',
        overflowX: 'hidden'
      }}>
        <div className="card">
          <div className="card-body">
            {error && !isLoading && (
              <div className="alert alert-danger" role="alert">
                {error}
              </div>
            )}

            {isCreating || isEditing ? (
              <RunbookForm
                runbook={editingRunbook}
                onSubmit={handleSubmitRunbook}
                onCancel={handleCancelForm}
                isSubmitting={isSubmitting}
              />
            ) : (
              <RunbookDetailView
                runbook={selectedRunbookIndex !== null ? runbooks[selectedRunbookIndex] : null}
                onEdit={handleEdit}
                onDelete={handleDeleteClick}
                showDeleteButton={showDeleteButton}
              />
            )}
          </div>
        </div>
      </div>

      {/* Delete Modal */}
      <DeleteConfirmationModal
        show={showDeleteModal}
        onHide={() => setShowDeleteModal(false)}
        onConfirm={handleConfirmDelete}
        isDeleting={isDeleting}
      />
    </div>
  );
};

// Update the main container to use our new self-contained component
const RunbooksContainer = ({ userData }) => {
  const [clientSystemId, setClientSystemId] = useState(null);
  const [runbooks, setRunbooks] = useState([]);
  const [selectedRunbookIndex, setSelectedRunbookIndex] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const location = useLocation();
  const navigate = useNavigate();
  const [cSearchParams] = useSearchParams();

  const queryClientSystemId = cSearchParams.get('client_system_id');

  const fetchRunbooks = (runbookIds = null) => {
    if (!clientSystemId) return;

    setIsLoading(true);
    setError(null);

    // Define the types we want to show
    const supportedTypes = [
      'planner',
      'log_analysis_agent',
      'event_analysis_agent',
      'dashboard_analysis_agent',
      'metric_analysis_agent',
    ];

    // Create query parameter for types
    const typeParams = supportedTypes.map(type => `type=${type}`).join('&');

    // Add runbook IDs to query if provided
    let apiUrl = `/api/runbooks?client_system_id=${clientSystemId}&${typeParams}`;
    if (runbookIds && runbookIds.length > 0) {
      const idsParam = runbookIds.join(',');
      apiUrl += `&ids=${idsParam}`;
    }

    makeAuthenticatedRequest(apiUrl)
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error(`Server returned ${response.status}: ${response.statusText}`);
        }
      })
      .then(data => {
        setRunbooks(data.runbooks);
        setIsLoading(false);
      })
      .catch(error => {
        console.error('Error fetching runbooks:', error);
        setError('Failed to load runbooks. Please try again later.');
        setIsLoading(false);
      });
  };

  // Handle changes from the RunbookListAndDetailView component
  const handleRunbookChange = (action, data) => {
    if (action === 'create' || action === 'update') {
      fetchRunbooks();
      if (action === 'create' && data?.id) {
        // Find the new runbook index and select it
        setTimeout(() => {
          const newIndex = runbooks.findIndex(r => r.id === data.id);
          if (newIndex !== -1) {
            setSelectedRunbookIndex(newIndex);
            updateUrlWithRunbookId(data.id);
          }
        }, 500);
      }
    } else if (action === 'delete') {
      fetchRunbooks();
      setSelectedRunbookIndex(null);
      const searchParams = new URLSearchParams(location.search);
      searchParams.delete('runbook_id');
      navigate(`${location.pathname}?${searchParams.toString()}`, { replace: true });
    }
  };

  const updateUrlWithRunbookId = (runbookId) => {
    const searchParams = new URLSearchParams(location.search);
    searchParams.set('runbook_id', runbookId.toString());
    navigate(`${location.pathname}?${searchParams.toString()}`, { replace: true });
  };

  useEffect(() => {
    // Set client system ID from query parameter or user data
    if (userData && userData.workspaces && userData.workspaces.length > 0) {
      setClientSystemId(queryClientSystemId || userData.workspaces[0].client_system_id);
    }
  }, [userData, queryClientSystemId]);

  useEffect(() => {
    if (clientSystemId) {
      fetchRunbooks();
    }
  }, [clientSystemId]);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const runbookId = searchParams.get('runbook_id');

    if (runbookId && runbooks.length > 0) {
      const runbookIndex = runbooks.findIndex(r => r.id === parseInt(runbookId, 10));
      if (runbookIndex !== -1) {
        setSelectedRunbookIndex(runbookIndex);
      }
    }
  }, [location.search, runbooks]);

  return (
    <div className="d-flex">
      <Sidebar clientSystemId={clientSystemId} />
      <div className="container-fluid" style={{
        marginLeft: '64px',
        maxWidth: 'calc(100vw - 64px)',
        transition: 'margin-left 0.2s ease-in-out'
      }}>
        {isLoading ? (
          <div className="text-center py-5">
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div>
        ) : error ? (
          <div className="alert alert-danger my-3" role="alert">
            {error}
          </div>
        ) : (
          <RunbookListAndDetailView
            runbooks={runbooks}
            clientSystemId={clientSystemId}
            initialSelectedIndex={selectedRunbookIndex}
            onRunbookChange={handleRunbookChange}
            showDeleteButton={true}
            showCreateButton={true}
          />
        )}
      </div>
    </div>
  );
};

// Create the auth component
function Runbooks(props) {
  return <RunbooksContainer {...props} />;
}

export default withAuth(Runbooks);