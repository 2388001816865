import { useState, useEffect, useMemo, createRef } from 'react';
import { Button, Spinner, Card, Accordion, Badge, Modal } from 'react-bootstrap';
import { SimilarIssues, ReadableRootCause, CollapsibleCard, IdentifierMapView, getStepTitle } from './common';
import {
  MetricAnalysisStepView, LogAnalysisStepView, WidgetAnalysisStepView, FeedbackButtons, EventAnalysisDataView,
  ApmSpanAnalysisStepView, ApmMetricsAnalysisStepView, EventAnalysisStepView, RunbookAnalysisView, fetchIssueAnalysisStep, processModuleSteps, AlertingMetricAnalysisView
} from './common';
import {makeAuthenticatedRequest} from '../../routes/utils';
import {GithubCommitInfo} from './code_commit_view';
import ReactMarkdown from 'react-markdown';
import { IoWarning, IoAddCircleOutline, IoRemoveCircleOutline, IoPencil } from 'react-icons/io5';
import { Form } from 'react-bootstrap';
import { RunbookModal } from './runbook_components';

import '../demo.css';


export const moduleNameToTitle = (moduleName, moduleConfig) => {
  if (moduleConfig?.readable_analysis_name){
    return moduleConfig.readable_analysis_name;
  }

  if (moduleName === 'alert_frequency_agent'){
    return 'Alert Frequency Analysis';
  } else if (moduleName === 'log_analysis_agent'){
    return 'Log Analysis';
  } else if (moduleName === 'dashboard_analysis_agent') {
    return 'Dashboard Analysis';
    //} else if (moduleName === 'alert_metadata_extractor_agent') {
    //return 'Monitor Analysis';
  } else if (moduleName === 'code_commit_analysis_agent') {
    return 'Code Analysis';
  } else if (moduleName === 'event_analysis_agent') {
    return 'Event Analysis';
  } else if (moduleName === 'runbook_analysis_agent') {
    return 'Relevant Documents';
  } else if (moduleName === 'user_input') {
    return 'User Input';
  } else if (moduleName === 'apm_analysis_agent') {
    return 'APM Analysis';
  } else if (moduleName === 'response') {
    return 'Relvy AI';
  } else if (moduleName === 'alerting_metric_analysis_agent') {
    return 'Alerting Metric Analysis';
  } else {
    return '';
  }
};

const AnalysisSteps = (props) => {
  const scrollRef = useMemo(() => createRef(), []);
  const [openKeys, setOpenKeys] = useState([]);

  const handleSelect = (newActiveKeys) => {
    setOpenKeys(newActiveKeys);
  };

  return (
    props.displaySteps && props.displaySteps.length > 0 && (
      <>
        <Accordion alwaysOpen onSelect={handleSelect}>
          {props.displaySteps.map((step, index) => (
            <Accordion.Item key={index} eventKey={index.toString()} id={'step-' + (index+1)}>
              <Accordion.Header>
                {<div className="font-size-085">{getStepTitle(step, index)}</div>}
              </Accordion.Header>
              <Accordion.Body>
                {openKeys.includes(index.toString()) && (
                  <>
                    {step['step_type'] === 'log_analysis' && <LogAnalysisStepView stepIndx={index + 1} analysisStepData={step} />}
                    {step['step_type'] === 'widget_analysis' && <WidgetAnalysisStepView stepIndx={index + 1} analysisStepData={step} />}
                    {step['step_type'] === 'apm_span_analysis' && <ApmSpanAnalysisStepView stepIndx={index + 1} analysisStepData={step} />}
                    {step['step_type'] === 'apm_metrics_analysis' && <ApmMetricsAnalysisStepView stepIndx={index + 1} analysisStepData={step} />}
                    {step['step_type'] === 'event_analysis' && <EventAnalysisStepView stepIndx={index + 1} analysisStepData={step} />}
                  </>
                )}
              </Accordion.Body>
            </Accordion.Item>
          ))}
        </Accordion>
      </>
    )
  );
};

export const ModuleAnalysisDataView = ({ moduleName, moduleData }) => {
  if (moduleName === 'code_commit_analysis_agent') {
    return <GithubCommitInfo commitInfo={moduleData} showConclusion={false} />
  } else if (moduleName === 'event_analysis_agent') {
    return <EventAnalysisDataView events={moduleData?.relevant_events} />
  } else if (moduleName === 'runbook_analysis_agent') {
    return <RunbookAnalysisView findings={moduleData} />
  } else if (moduleName === 'alerting_metric_analysis_agent') {
    return <AlertingMetricAnalysisView findings={moduleData} />
  }
  return '';
};

export const ModuleAnalysisView = (props) => {
  const [displaySteps, setDisplaySteps] = useState(null);
  const [isLoadingSteps, setIsLoadingSteps] = useState(true);

  useEffect(() => {
    // Load steps in background if they exist
    if (props.moduleOutput?.displaySteps) {
      const loadSteps = async () => {
        try {
          // Here you would fetch the steps data for this specific module
          const steps = await Promise.all(
            props.moduleOutput.displaySteps.map(step =>
              fetchIssueAnalysisStep(props.issue.id, step.stepId)
            )
          );
          const processedSteps = processModuleSteps(steps);
          setDisplaySteps(processedSteps);
        } catch (error) {
          console.error('Error loading steps:', error);
        } finally {
          setIsLoadingSteps(false);
        }
      };

      loadSteps();
    } else {
      setIsLoadingSteps(false);
    }
  }, [props.moduleOutput]);

  // Skip if module has no data to display
  if (!props.moduleOutput?.analysis_data?.rca &&
      !props.moduleOutput?.analysis_data?.user_input &&
      !props.moduleOutput?.analysis_data?.response &&
      !props.moduleOutput?.analysis_data?.display_error_message &&
      (!props.moduleOutput?.analysis_data?.identifier_updates || Object.keys(props.moduleOutput?.analysis_data?.identifier_updates).length === 0) &&
      !props.moduleOutput?.displaySteps) {
    return null;
  }

  const moduleTitle = moduleNameToTitle(props.moduleOutput['module_name'], props.moduleOutput['module_config']);
  if (!moduleTitle) return null;
  const moduleName = props.moduleOutput['module_name'];

  if (moduleName === 'user_input') {
    return (
      <CollapsibleCard title={moduleTitle} bodyClassName='font-size-085'>
        <ReactMarkdown>{props.moduleOutput?.analysis_data?.user_input}</ReactMarkdown>
      </CollapsibleCard>
    );
  }

  if (moduleName === 'response') {
    return (
      <CollapsibleCard title={moduleTitle} bodyClassName='font-size-085'>
        <ReactMarkdown>{props.moduleOutput?.analysis_data?.response}</ReactMarkdown>
      </CollapsibleCard>
    );
  }

  if (props.moduleOutput?.analysis_data?.display_error_message) {
    return (
      <CollapsibleCard title={moduleTitle} bodyClassName='font-size-085' defaultCollapsed={true}>
        <ReactMarkdown>{props.moduleOutput?.analysis_data?.display_error_message}</ReactMarkdown>
      </CollapsibleCard>
    );
  }

  const rcaSections = props.moduleOutput?.analysis_data?.rca && props.moduleOutput?.analysis_data?.rca.trim() ?
    props.moduleOutput.analysis_data.rca.split('\n')
      .filter(section => section.trim()) // Filter out empty strings
      .map((section, index) => (
        <div key={index}>
          <div
            className="insight-container"
            style={{
              position: 'relative',
              backgroundColor: '#f8f9fa',
              borderRadius: '8px',
              padding: '12px 16px',
              boxShadow: '0 1px 3px rgba(0,0,0,0.05)',
            }}
          >
            <div className="d-flex justify-content-between align-items-start gap-3">
              <div className="insight-text">
                {section}
              </div>
            </div>
            <div className="insight-accent-line" />
          </div>
        </div>
      )) : null;

  return (
    <CollapsibleCard id={`module-${props.moduleOutput['id']}`} title={moduleTitle} bodyClassName="font-size-085" defaultCollapsed={true}>
      {moduleName !== 'runbook_analysis_agent' && rcaSections}
      {props.moduleOutput?.analysis_data?.identifier_updates && (
        <IdentifierMapView identifierMap={props.moduleOutput['analysis_data']['identifier_updates']} />
      )}
      {props.moduleOutput?.analysis_data?.data && (
        <ModuleAnalysisDataView
          moduleName={props.moduleOutput['module_name']}
          moduleData={props.moduleOutput['analysis_data']['data']}
        />
      )}

      {/* Show steps section with loading state */}
      {(displaySteps || isLoadingSteps) && (
        <>
          <h6 className="mb-2 mt-2 text-muted" style={{fontSize: '0.9rem'}}>Step-by-Step Root Cause Analysis</h6>
          {isLoadingSteps ? (
            <div className="text-center py-3">
              <Spinner animation="border" role="status" size="sm">
                <span className="visually-hidden">Loading steps...</span>
              </Spinner>
            </div>
          ) : (
            <AnalysisSteps displaySteps={displaySteps} />
          )}
        </>
      )}
    </CollapsibleCard>
  );
};

export const AnalysisActionButtons = ({
  feedbackState,
  handleFeedback,
  onEditInstructions,
  isLoading
}) => {
  return (
    <div className="d-flex justify-content-end align-items-center mt-3 gap-2">
      <Button
        className="outline-custom-btn"
        // variant="outline-primary"
        size="sm"
        onClick={onEditInstructions}
        disabled={isLoading}
      >
        {isLoading ? (
          <>
            <Spinner animation="border" size="sm" className="me-2" />
            Loading...
          </>
        ) : (
          <>
            <IoPencil className="me-1" /> Edit Instructions
          </>
        )}
      </Button>
      <FeedbackButtons
        feedbackState={feedbackState}
        handleFeedback={handleFeedback}
      />
    </div>
  );
};

export const RCASection = ({ analysisData, issueId, clientSystemId, scrollToModuleIndx }) => {
  const [feedbackState, setFeedbackState] = useState(null);
  const [noisynessExpanded, setNoisynessExpanded] = useState(false);
  const [showRunbookModal, setShowRunbookModal] = useState(false);
  const [runbooks, setRunbooks] = useState([]);
  const [isLoadingRunbooks, setIsLoadingRunbooks] = useState(false);

  useEffect(() => {
    if (analysisData?.feedbackScore === 1) {
      setFeedbackState(1);
    } else if (analysisData?.feedbackScore === -1) {
      setFeedbackState(-1);
    } else {
      setFeedbackState(null);
    }
  }, [analysisData?.feedbackScore]);

  const handleFeedback = async (score) => {
    try {
      const response = await makeAuthenticatedRequest(`/api/dashboard/v2/issues/${issueId}/feedback`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ feedback_score: score }),
      });

      if (response.ok) {
        setFeedbackState(score);
      } else {
        console.error("Failed to submit feedback");
        console.log(response);
      }
    } catch (error) {
      console.error("Error submitting feedback:", error);
    }
  };

  const handleEditInstructions = async () => {
    setIsLoadingRunbooks(true);
    try {
      if (analysisData.relatedRunbooks && analysisData.relatedRunbooks.length > 0) {
        const idsParam = analysisData.relatedRunbooks.join(',');
        const response = await makeAuthenticatedRequest(`/api/runbooks?ids=${idsParam}&client_system_id=${clientSystemId}`);
        if (response.ok) {
          const data = await response.json();
          setRunbooks(data.runbooks.filter(runbook => runbook && runbook.id));
        } else {
          console.error('Error fetching runbooks');
        }
      }

      setShowRunbookModal(true);
    } catch (error) {
      console.error('Error fetching runbooks:', error);
    } finally {
      setIsLoadingRunbooks(false);
    }
  };

  const noisyness_module_output = analysisData.moduleOutputs.find(module => module.module_name === 'noisy_or_not_agent');
  const noisyness_classification_response = noisyness_module_output?.analysis_data?.data?.classification_response;
  const noisyness_classification_reason = noisyness_module_output?.analysis_data?.data?.reason;
  const noisyness_config = {
    needs_attention: {
      variant: 'danger',
      color: '#dc3545',
      bgColor: '#dc35451a',
      bgHoverColor: '#dc35452a',
      borderColor: '#dc35454d',
      text: 'Needs Attention'
    },
    noisy: {
      variant: 'warning',
      color: '#997404',
      bgColor: '#fff3cd',
      bgHoverColor: '#ffe69c',
      borderColor: '#ffeeba',
      text: 'Likely Noisy'
    }
  };

  const getCardContent = () => {
    if (!analysisData.combinedResponse) {
      return {
        title: "Overall summary",
        body: "No data available",
      };
    }

    const config = noisyness_config[noisyness_classification_response];

    const content = (
      <>
        {analysisData?.combinedResponse?.readable_rca && (
          <ReadableRootCause
            readableRCA={analysisData.combinedResponse.readable_rca}
            referenceClickHandler={scrollToModuleIndx}
          />
        )}
        {analysisData?.combinedResponse?.identifier_map && (
          <IdentifierMapView identifierMap={analysisData.combinedResponse.identifier_map} />
        )}
        {noisyness_classification_response && (
          <div className="mt-3">
            <div
              onClick={() => setNoisynessExpanded(!noisynessExpanded)}
              style={{
                display: 'inline-flex',
                alignItems: 'center',
                gap: '6px',
                cursor: 'pointer',
                backgroundColor: config.bgColor,
                border: `1px solid ${config.borderColor}`,
                borderRadius: '4px',
                padding: '4px 8px',
                transition: 'all 0.2s ease'
              }}
              onMouseOver={(e) => e.currentTarget.style.backgroundColor = config.bgHoverColor}
              onMouseOut={(e) => e.currentTarget.style.backgroundColor = config.bgColor}
            >
              <IoWarning color={config.color} size={14} />
              <span style={{ color: config.color, fontSize: '0.7rem', fontWeight: 500 }}>
                {config.text}
              </span>
              {noisynessExpanded ? (
                <IoRemoveCircleOutline color={config.color} size={14} />
              ) : (
                <IoAddCircleOutline color={config.color} size={14} />
              )}
            </div>
            {noisynessExpanded && (
              <div
                className="mt-2 p-3 rounded font-size-085"
                style={{
                  backgroundColor: '#f8f9fa',
                  border: '1px solid #dee2e6',
                  color: '#2c3e50'
                }}
              >
                {noisyness_classification_reason}
              </div>
            )}
          </div>
        )}
      </>
    );

    return {
      title: "Overall summary",
      body: content,
    };
  }

  const content = getCardContent();

  return (
    <>
      <CollapsibleCard title={content.title}>
        {content.body}
        <AnalysisActionButtons
          feedbackState={feedbackState}
          handleFeedback={handleFeedback}
          onEditInstructions={handleEditInstructions}
          isLoading={isLoadingRunbooks}
        />
      </CollapsibleCard>

      <RunbookModal
        show={showRunbookModal}
        onHide={() => setShowRunbookModal(false)}
        runbooks={runbooks}
        clientSystemId={clientSystemId}
      />
    </>
  );
};

export const AnalysisDataViewV2 = (props) => {
    const [userHint, setUserHint] = useState('');
    const [isInterrupting, setIsInterrupting] = useState(false);
    const [isPrompting, setIsPrompting] = useState(false);

    useEffect(() => {
      if (props.analysisData && props.analysisData.state === 'INTERRUPTING') {
        setIsInterrupting(true);
      } else {
        setIsInterrupting(false);
      }

    }, [props.analysisData]);

    const handleInterrupt = () => {
      setIsInterrupting(true);
      props.onInterrupt().then(() => {
        //setIsInterrupting(false);
      }).catch(() => {
        setIsInterrupting(false);
      });
    };

    const handlePrompt = () => {
      setIsPrompting(true);
      const result = props.onUserPrompt(userHint);
      Promise.resolve(result)
        .then(() => {
          setIsPrompting(false);
          setUserHint("");
        })
        .catch((error) => {
          console.error("Error in onUserPrompt:", error);
          setIsPrompting(false);
        });
    };

    const handleKeyDown = (event) => {
      if (event.key === 'Enter') {
        handlePrompt();
      }
    };

    const scrollToModuleIndx = (moduleIndx) => {
      const element = document.getElementById(`module-${moduleIndx}`);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }

    if (!props.analysisData) {
      return (
        <div>
          <Button
              variant="success"
              type="submit"
              className="align-self-end" onClick={props.onRunAnalysis}>Run Analysis</Button>
        </div>
        );
    }

    return (
      <div>
        <RCASection
          analysisData={props.analysisData}
          issueId={props.issue.id}
          clientSystemId={props.issue.clientSystemId}
          scrollToModuleIndx={scrollToModuleIndx}
        />

      {/* {props.analysisData && props.analysisData.relatedIssueData && props.analysisData.relatedIssueData.length > 0 && (<SimilarIssues analysisData={props.analysisData} selectIssueFromIdHandler={props.selectIssueFromIdHandler} />)} */}
      {/* {props.analysisData && props.analysisData.identifiersToAskFor && props.analysisData.identifiersToAskFor.length > 0 && (
        <>
        <RequestingInfo analysisData={props.analysisData} onUserPrompt={props.onUserPrompt} />
      </>)
      } */}

      {props.analysisData.moduleOutputs.map((moduleOutput, index) => (
        <ModuleAnalysisView key={index} moduleOutput={moduleOutput} issue={props.issue} />
      ))}

      {/* Spinner for ongoing analysis */}
      {props.analysisData.stillRunning && (<div className="d-flex justify-content-between align-items-center mt-3">
        <div className="flex-grow-1">
          {props.analysisData.stillRunning && (
            <div className="d-flex align-items-center">
              <Spinner animation="border" role="status" size="sm">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
              <span style={{ color: '#5935c9', marginLeft: '8px' }} className="ms-2 font-size-085">{props.analysisData.progressData?.progress_str}</span>
            </div>
          )}
        </div>
        {/* <button
          className="btn btn-outline-danger ms-3"
          onClick={handleInterrupt}
          disabled={isInterrupting}
        >
          {isInterrupting ? (
            <>
              <Spinner animation="border" size="sm" className="me-2" />
              Stopping...
            </>
          ) : (
            'Stop Analysis'
          )}
        </button> */}
      </div>)}

      {/* Prompt for user input */}
      {(
        <div className="card my-3">
          <div className="row mx-3 my-3">
            <div className="col-md-11">
              <input
                type="text"
                placeholder="Ask Relvy to look at other logs or metrics..."
                className="form-control"
                id="alsoLookAt"
                value={userHint}
                onChange={(e) => setUserHint(e.target.value)}
                onKeyDown={handleKeyDown}
              />
            </div>
            <div className="col-md-1">
              <button
                type="submit"
                className="btn btn-primary custom-btn m-0"
                style={{
                  backgroundColor: '#5935c9',
                  borderColor: '#5935c9',
                  color: 'white',
                  transition: 'background-color 0.3s, color 0.3s'
                }}
                onClick={handlePrompt}
                disabled={true}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      )}
    </div>);
  };
