import React, { useState, useEffect } from 'react';
import { Button, Modal, Spinner } from 'react-bootstrap';
import {
  RunbookDetailView,
  RunbookForm,
  RunbookListAndDetailView
} from '../../routes/runbooks';
import { makeAuthenticatedRequest } from '../../routes/utils';

export const RunbookModal = ({ show, onHide, runbooks: initialRunbooks = [], clientSystemId, runbookIds = [] }) => {
  const [runbooks, setRunbooks] = useState(initialRunbooks);
  const [selectedRunbookIndex, setSelectedRunbookIndex] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const fetchRunbooks = () => {
    if (!clientSystemId) return;

    setIsLoading(true);
    setError(null);

    // Define the types we want to show
    const supportedTypes = [
      'planner',
      'log_analysis_agent',
      'event_analysis_agent',
      'dashboard_analysis_agent',
      'metric_analysis_agent',
    ];

    // Create query parameter for types
    const typeParams = supportedTypes.map(type => `type=${type}`).join('&');

    // Add runbook IDs to query if provided
    let apiUrl = `/api/runbooks?client_system_id=${clientSystemId}&${typeParams}`;
    if (runbookIds && runbookIds.length > 0) {
      const idsParam = runbookIds.join(',');
      apiUrl += `&ids=${idsParam}`;
    }

    makeAuthenticatedRequest(apiUrl)
      .then(response => {
        console.log('response', response);
        if (response.ok) {
          return response.json();
        } else {
          throw new Error(`Server returned ${response.status}: ${response.statusText}`);
        }
      })
      .then(data => {
        // Ensure we only set valid runbooks
        const validRunbooks = data.runbooks.filter(runbook => runbook && runbook.id);
        setRunbooks(validRunbooks);
        if (validRunbooks.length > 0) {
          setSelectedRunbookIndex(0);
        }
      })
      .catch(error => {
        console.error('Error fetching runbooks:', error);
        setError('Failed to load runbooks. Please try again later.');
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  // Handle changes from the RunbookListAndDetailView component
  const handleRunbookChange = (action, data) => {
    fetchRunbooks();
  };

  // Fetch runbooks when the modal is shown or when runbookIds changes
  useEffect(() => {
    if (show && clientSystemId) {
      fetchRunbooks();
    }
  }, [show, clientSystemId, JSON.stringify(runbookIds)]);

  // Initialize with provided runbooks
  useEffect(() => {
    if (initialRunbooks && initialRunbooks.length > 0) {
      // Filter out any invalid runbook entries
      const validRunbooks = initialRunbooks.filter(runbook => runbook && runbook.id);
      setRunbooks(validRunbooks);
      if (validRunbooks.length > 0) {
        setSelectedRunbookIndex(0);
      }
    }
  }, [initialRunbooks]);

  return (
    <Modal show={show} onHide={onHide} size="xl" dialogClassName="modal-90w">
      <Modal.Header closeButton>
        <Modal.Title>Runbooks</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {isLoading ? (
          <div className="text-center py-4">
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div>
        ) : error ? (
          <div className="alert alert-danger" role="alert">
            {error}
          </div>
        ) : (
          <RunbookListAndDetailView
            runbooks={runbooks}
            clientSystemId={clientSystemId}
            initialSelectedIndex={selectedRunbookIndex}
            onRunbookChange={handleRunbookChange}
            showDeleteButton={false} // Hide delete button in modal
            showCreateButton={true}
          />
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};