import { createRef, useEffect, useMemo, useState } from 'react';
import { Accordion, Card, Spinner, Button } from 'react-bootstrap';

// import {CustomPlaceholder, ParagraphPlaceholder} from '../../routes/issue_details_components';
// import {Attachment, AttachmentModal, Breadcrumb, CommentsCard} from '../../routes/issue_details_components';

import '../demo.css';
import '../css/issue_details.css';

import {makeAuthenticatedRequest} from '../../routes/utils';

import {SimilarIssues, MetricAnalysisStepView, LogAnalysisStepView, FeedbackButtons} from './common';
import {GithubCommitInfo} from './code_commit_view';

const RequestingInfo = (props) => {
    const [idValues, setIdValues] = useState([]);

    useEffect(() => {
      setIdValues(props.analysisData.identifiersToAskFor.map(() => ''));
    }, []);

    const handleInputChange = (index) => (event) => {
      const newIdValues = [...idValues];
      newIdValues[index] = event.target.value;
      setIdValues(newIdValues);
    }


    const onSubmit = () => {
      var userPrompt = props.analysisData.identifiersToAskFor[0] + ": " + idValues[0];
      for (var indx=1; indx < idValues.length; indx++) {
        if (idValues[indx].trim().length === 0) {
          continue;
        }
        userPrompt += ", " + props.analysisData.identifiersToAskFor[indx] + ": " + idValues[indx];
      }
      props.onUserPrompt(userPrompt);
    }


    return (
      <Card className="mb-3">
        <Card.Body>
          <Card.Title>Requesting Information</Card.Title>
          <Card.Text>
            <div className="row">
              <div className="col-md-12">
                <p>We think the following information would be helpful to diagnose the issue. Please provide if you can:</p>
              </div>
            </div>
              {props.analysisData.identifiersToAskFor.map((identifier, index) => (
                <div className="row my-2" key={index}>
                  <div className="col-md-3">
                    {identifier}
                  </div>
                  <div className="col-md-9">
                    <input type="text" className="form-control"
                    onChange={handleInputChange(index)}
                    value={idValues[index]} />
                  </div>
                </div>
              ))}
                <div className="row my-3">
                  <div className="col-md-2">
                    <button
                      type="submit"
                      className="btn btn-primary custom-btn m-0"
                      style={{
                        backgroundColor: '#5935c9',
                        borderColor: '#5935c9',
                        color: 'white',
                        transition: 'background-color 0.3s, color 0.3s'
                      }}
                      onClick={onSubmit}
                    >
                      Submit
                    </button>
                  </div>
                </div>
          </Card.Text>
        </Card.Body>
      </Card>
      );
  };



  const AnalysisSteps = (props) => {
    const getStepTitle = (step, index) => {
      if (step['step_type'] === 'ongoing') {
        return (
          <>
            <span className="step-number">Step {index + 1}</span>
            <span className="highlight-ongoing">Currently running</span>: {step['currentNextStep']}
          </>
        );
      } else if (step['step_type'] === 'user_input') {
        return (
          <>
            <span className="step-number">Step {index + 1}</span>
            <span className="highlight-logs">User Suggestion</span>
            <code className="service-name">{step['message']}</code>
          </>
        );
      } else if (step['step_type'] === 'log_analysis') {
        return (
          <>
            <span className="step-number">Step {index + 1}</span>Analyzing
            {' '}
            <span className="highlight-logs">logs</span>
            {' '}
            from service
            {' '}
            <code className="service-name" style={{ maxWidth: '150px' }}>{step['file_analyzed']}</code>
          </>
        );
      } else {
        return (
          <>
            <span className="step-number">Step {index + 1}</span>Analyzing
            {' '}
            <span className="highlight-logs">metric</span>
            {' '}
            <code className="service-name" style={{ maxWidth: '400px' }}>{step['metric_analyzed']}</code>
            {' '}
            from service
            {' '}
            <code className="service-name" style={{ maxWidth: '150px' }}>{step['service_analyzed']}</code>
          </>
        );
      }
    };

    const scrollRef = useMemo(() => createRef(), []);

    useEffect(() => {
      if (props.analysisData.stillRunning && scrollRef.current) {
        scrollRef.current.scrollIntoView({ behavior: 'smooth' });
      }
    }, []);

    return (
      props.analysisData && props.analysisData.analysisSteps.length > 0 && (
      <Card className="my-3 analysis-steps">
        <Card.Header className="card-header-custom"><strong>Step-by-Step Root Cause Analysis</strong></Card.Header>
        <Card.Body>
          <Accordion alwaysOpen>
            {props.analysisData.analysisSteps.map((step, index) => (
              <Accordion.Item key={index} eventKey={index.toString()} id={'step-' + (index+1)}>
                <Accordion.Header>
                {getStepTitle(step, index)}
                  {/* Step {index + 1}: {step['step_type'] === 'log_analysis' ? 'Log Analysis' : 'Metric Analysis'} */}
                </Accordion.Header>
                <Accordion.Body>
                  {step['step_type'] === 'log_analysis' && <LogAnalysisStepView stepIndx={index + 1} analysisStepData={step} />}
                  {step['step_type'] === 'metrics_analysis' && <MetricAnalysisStepView stepIndx={index + 1} analysisStepData={step} />}
                </Accordion.Body>
              </Accordion.Item>
            ))}
          </Accordion>
        </Card.Body>
        <div ref={scrollRef} />
      </Card>
    )
    );
  };



  const InitialAssessment = ({ initialAssessment }) => {
    let sections = []
    for (let qa of initialAssessment) {
      sections.push({text: qa.answer});
    }

    return (
      <div className="readable-root-cause">
        {sections.length > 0 && (
          <div className="d-flex flex-column gap-3">
            {sections.map((section, index) => {
              if (typeof section.text !== 'string') return null;

              return (
                <div
                  key={index}
                  className="insight-container"
                  style={{
                    position: 'relative',
                    backgroundColor: '#f8f9fa',
                    borderRadius: '8px',
                    padding: '12px 16px',
                    boxShadow: '0 1px 3px rgba(0,0,0,0.05)',
                  }}
                >
                  <div className="d-flex justify-content-between align-items-start gap-3">
                    <div className="insight-text">
                      {section.text}
                    </div>
                  </div>
                  <div className="insight-accent-line" />
                </div>
              );
            })}
          </div>
        )}
      </div>
    );
  };


  const ReadableRootCause = ({ rootCause, githubCommitInfo, referenceClickHandler }) => {
    const sections = rootCause?.sections || [];

    return (
      <div className="readable-root-cause">
        {rootCause && sections.length > 0 && (
          <div className="d-flex flex-column gap-3">
            {sections.map((section, index) => {
              if (typeof section.text !== 'string') return null;

              return (
                <div
                  key={index}
                  className="insight-container"
                  style={{
                    position: 'relative',
                    backgroundColor: '#f8f9fa',
                    borderRadius: '8px',
                    padding: '12px 16px',
                    boxShadow: '0 1px 3px rgba(0,0,0,0.05)',
                  }}
                >
                  <div className="d-flex justify-content-between align-items-start gap-3">
                    <div className="insight-text">
                      {section.text}
                    </div>
                    {section.references && Array.isArray(section.references) && (
                      <div className="insight-references">
                        {section.references.map((ref, refIndex) => (
                          <button
                            key={refIndex}
                            onClick={() => referenceClickHandler(ref)}
                            className="badge bg-light text-primary border border-primary-subtle mx-1 px-2 py-1"
                            onMouseOver={(e) => e.target.style.backgroundColor = '#e8f0fe'}
                            onMouseOut={(e) => e.target.style.backgroundColor = '#fff'}
                          >
                            #{ref}
                          </button>
                        ))}
                      </div>
                    )}
                  </div>
                  <div className="insight-accent-line" />
                </div>
              );
            })}
          </div>
        )}
        {githubCommitInfo && <GithubCommitInfo commitInfo={githubCommitInfo} showConclusion={true} />}
      </div>
    );
  };

  const AnalysisContent = ({ issue, analysisData, scrollToStepIndx }) => {
    const [feedbackState, setFeedbackState] = useState(null);

    useEffect(() => {
      if (analysisData?.feedbackScore === 1) {
        setFeedbackState(1);
      } else if (analysisData?.feedbackScore === -1) {
        setFeedbackState(-1);
      } else {
        setFeedbackState(null);
      }
    }, [analysisData?.feedbackScore]);

    const handleFeedback = async (score) => {
      try {
        const response = await makeAuthenticatedRequest(`/api/dashboard/v2/issues/${issue.id}/feedback`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ feedback_score: score }),
        });

        if (response.ok) {
          setFeedbackState(score);
        } else {
          console.error("Failed to submit feedback");
          console.log(response);
        }
      } catch (error) {
        console.error("Error submitting feedback:", error);
      }
    };

    const getCardContent = () => {
      // Case 1: User message response
      if (analysisData?.nextStep === 'response') {
        return {
          title: 'Relvy AI',
          body: <Card.Text>{analysisData.nextStepReason}</Card.Text>
        };
      }

      // Case 2: No data available yet
      const hasAnyData = analysisData?.rootCause ||
                        analysisData?.possibleRootCauseSoFar ||
                        analysisData?.githubCommitInfo || analysisData?.initialAssessment;

      if (!hasAnyData) {
        return {
          title: <strong>Analysis in Progress</strong>,
          body: (
            <Card.Text>
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            </Card.Text>
          )
        };
      }

      // Case 3: Root cause found
      if (analysisData.rootCause) {
        const body = (
          <>
            {analysisData.initialAssessment && (
              <InitialAssessment initialAssessment={analysisData.initialAssessment} />
            )}
            {analysisData.readableRootCause ? (
              <ReadableRootCause
                rootCause={analysisData.readableRootCause}
                githubCommitInfo={analysisData.githubCommitInfo}
                referenceClickHandler={scrollToStepIndx}
              />
            ) : (
              <Card.Text>{analysisData.rootCause}</Card.Text>
            )}
            {/* {analysisData.nextStep && analysisData.nextStep !== "root_cause" && (
              <div className="mt-3">
                <div className="mb-2 border-bottom pb-2">
                  <strong>Recommended Action:</strong>
                </div>
                <p style={{ fontSize: '0.9rem' }}>{analysisData.nextStep}</p>
                <p style={{ fontSize: '0.9rem' }}>{analysisData.nextStepReason}</p>
              </div>
            )} */}
          </>
        );
        return {
          title: <strong>Possible Root Cause</strong>,
          body: body,
        };
      }

      // Case 4: Partial analysis results
      const rootCauseContent = <>
        {analysisData.initialAssessment && (
          <InitialAssessment initialAssessment={analysisData.initialAssessment} />
        )}
        {analysisData.possibleRootCauseSoFar ? (
          analysisData.readableRootCauseSoFar ? (
            <ReadableRootCause
              rootCause={analysisData.readableRootCauseSoFar}
              githubCommitInfo={analysisData.githubCommitInfo}
              referenceClickHandler={scrollToStepIndx}
            />
          ) : (
            <Card.Text>{analysisData.possibleRootCauseSoFar}</Card.Text>
          )
        ) : (
          // Case 5: Only GitHub commit info
          analysisData.githubCommitInfo ? (
            <ReadableRootCause
              rootCause={null}
              githubCommitInfo={analysisData.githubCommitInfo}
              referenceClickHandler={scrollToStepIndx}
            />
          ) : null
        )}
      </>;

      return {
        title: <strong>Analysis Summary, so far</strong>,
        body: rootCauseContent
      };
    };

    const content = getCardContent();

    return (
      <Card className="mb-3">
        <Card.Header className="card-header-custom">{content.title}</Card.Header>
        <Card.Body>
          {content.body}
          {!analysisData.stillRunning && (
            <FeedbackButtons
              feedbackState={feedbackState}
              handleFeedback={handleFeedback}
            />
          )}
        </Card.Body>
      </Card>
    );
  };

  const RCASection = ({ issue, analysisData, scrollToStepIndx }) => {
    if (analysisData && analysisData.combinedResponse){
      return <ReadableRootCause rootCause={analysisData.combinedResponse['readable_root_cause']} githubCommitInfo={null} referenceClickHandler={scrollToStepIndx} />;
    }
    return '';
  };


export const AnalysisDataView = (props) => {
    const [userHint, setUserHint] = useState('');
    const [isInterrupting, setIsInterrupting] = useState(false);
    const [isPrompting, setIsPrompting] = useState(false);

    useEffect(() => {
      if (props.analysisData && props.analysisData.state === 'INTERRUPTING') {
        setIsInterrupting(true);
      } else {
        setIsInterrupting(false);
      }

    }, [props.analysisData]);

    const handleInterrupt = () => {
      setIsInterrupting(true);
      props.onInterrupt().then(() => {
        //setIsInterrupting(false);
      }).catch(() => {
        setIsInterrupting(false);
      });
    };

    const handlePrompt = () => {
      setIsPrompting(true);
      const result = props.onUserPrompt(userHint);
      Promise.resolve(result)
        .then(() => {
          setIsPrompting(false);
          setUserHint("");
        })
        .catch((error) => {
          console.error("Error in onUserPrompt:", error);
          setIsPrompting(false);
        });
    };

    const handleKeyDown = (event) => {
      if (event.key === 'Enter') {
        handlePrompt();
      }
    };

    const scrollToStepIndx = (stepIndx) => {
      const element = document.getElementById(`step-${stepIndx}`);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }

    if (!props.analysisData) {
      return (
        <div>
          <Button
              variant="success"
              type="submit"
              className="align-self-end" onClick={props.onRunAnalysis}>Run Analysis</Button>
        </div>
        );
    }

    return (
      <div>
        <AnalysisContent
          issue={props.issue}
          analysisData={props.analysisData}
          scrollToStepIndx={scrollToStepIndx}
        />
        {/* {!props.analysisData.stillRunning && (
          <FeedbackButtons
            feedbackState={feedbackState}
            handleFeedback={handleFeedback}
          />
        )} */}

      {props.analysisData && props.analysisData.relatedIssueData && props.analysisData.relatedIssueData.length > 0 && (<SimilarIssues analysisData={props.analysisData} selectIssueFromIdHandler={props.selectIssueFromIdHandler} />)}
      {/* {props.analysisData && props.analysisData.identifiersToAskFor && props.analysisData.identifiersToAskFor.length > 0 && (
        <>
        <RequestingInfo analysisData={props.analysisData} onUserPrompt={props.onUserPrompt} />
      </>)
      } */}

      <AnalysisSteps analysisData={props.analysisData} />

      {/* Spinner for ongoing analysis */}
      {props.analysisData.stillRunning && (<div className="d-flex justify-content-between align-items-center mt-3">
        <div className="flex-grow-1">
          {props.analysisData.state === 'IN_PROGRESS' && (
            <div className="d-flex align-items-center">
              <Spinner animation="border" role="status" size="sm">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
              <span style={{ color: '#5935c9', marginLeft: '8px' }} className="ms-2">Relvy working its magic....</span>
            </div>
          )}
        </div>
        <button
          className="btn btn-outline-danger ms-3"
          onClick={handleInterrupt}
          disabled={isInterrupting}
        >
          {isInterrupting ? (
            <>
              <Spinner animation="border" size="sm" className="me-2" />
              Stopping...
            </>
          ) : (
            'Stop Analysis'
          )}
        </button>
      </div>)}

      {/* Prompt for user input */}
      {true && (
        <div className="card my-3">
          <div className="row mx-3 my-3">
            <div className="col-md-11">
              <input
                type="text"
                placeholder="Ask Relvy to look at other logs or metrics..."
                className="form-control"
                id="alsoLookAt"
                value={userHint}
                onChange={(e) => setUserHint(e.target.value)}
                onKeyDown={handleKeyDown}
              />
            </div>
            <div className="col-md-1">
              <button
                type="submit"
                className="btn btn-primary custom-btn m-0"
                style={{
                  backgroundColor: '#5935c9',
                  borderColor: '#5935c9',
                  color: 'white',
                  transition: 'background-color 0.3s, color 0.3s'
                }}
                onClick={handlePrompt}
                disabled={isPrompting}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      )}
    </div>);
  };