import { GoogleLogin } from '@react-oauth/google';
import { useEffect, useState } from 'react';
import { getLoginInfoFromCookie } from './utils';
import { Col, Form, Button } from 'react-bootstrap';

const Landing = (props) => {
  const [loginState, setLoginState] = useState(null);

  const [message, setMessage] = useState(null);

  useEffect(() => {
    const loginCallback = (data) => {
      handleLoginSuccess(data);
    };

    const loginFailureCallback = () => {
      setLoginState('not_logged_in');
    };

    getLoginInfoFromCookie(loginCallback, loginFailureCallback);
  }, []);

  const handleLoginFailure = (data) => {
    if (props.onFailureCallback) {
      props.onFailureCallback(data);
    } else {
      if (data.message) {
        setMessage(data.message);
      } else {
        setMessage('Login failed. Unknown error');
      }
      setIsLoading(false);
    }
  };

  const handleLoginSuccess = (data) => {
    if (props.onSuccessCallback) {
      props.onSuccessCallback(data);
    } else {
      // Set token cookie expiration date to 1 week
      document.cookie = 'token=' + data.user.api_token + '; max-age=' + 60 * 60 * 24 * 7;

      // Check if there's a redirect URL saved in localStorage
      const redirectUrl = localStorage.getItem('loginRedirectUrl');

      if (redirectUrl) {
        // Clear the redirect URL from localStorage
        localStorage.removeItem('loginRedirectUrl');
        window.location.href = redirectUrl;
      } else if (!data.organization) {
        window.location.href = '/setup';
      } else if (!data.workspaces || data.workspaces.length === 0) {
        window.location.href = '/configure';
      } else {
        window.location.href = '/dashboard';
      }
    }
  };

  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || '';

  const onSuccessfulGoogleLogin = (credentialResponse) => {
    document.cookie = 'gloginInfo=' + JSON.stringify(credentialResponse);

    fetch(`${API_BASE_URL}/api/google_signin`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        'id_token': credentialResponse.credential,
      }),
    })
    .then((response) => response.json())
    .then(handleLoginSuccess);
  };

  const [loginMethod, setLoginMethod] = useState('google');

  const [email, setEmail] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [password, setPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleEmailLogin = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      let response = await fetch(`${API_BASE_URL}/api/onboarding/email_signin`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          'email': email,
          'password': password,
        }),
      });
      if (response.ok){
        let responseData = await response.json();
        handleLoginSuccess(responseData);
      } else {
        let responseData = await response.json();
        handleLoginFailure(responseData);
      }
    } catch (error) {
      handleLoginFailure({'message': 'Login failed. Unknown error'});
    }
  }

  const handleForgotPassword = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      let response = await fetch(`${API_BASE_URL}/api/onboarding/forgot_password`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          'email': email,
        }),
      });
      if (response.ok) {
        setMessage('Password reset link sent to your email');
        setIsLoading(false);
      } else {
        setMessage('Failed to send password reset link');
        setIsLoading(false);
      }
    } catch (error) {
      handleLoginFailure({'message': 'Failed to send password reset link. Unknown error'});
    }
  }

  const handleRegister = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    console.log('Register', email, firstName, lastName, password);
    try {
      let response = await fetch(`${API_BASE_URL}/api/onboarding/register`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          'email': email,
          'first_name': firstName,
          'last_name': lastName,
          'password': password,
        }),
      });
      if (response.ok){
        let responseData = await response.json();
        handleLoginSuccess(responseData);
      } else {
        let responseData = await response.json();
        handleLoginFailure(responseData);
      }
    } catch (error) {
      handleLoginFailure({'message': 'Registration failed. Unknown error'});
    }
  }

  return (
    <div className="container d-flex flex-column" style={{ minHeight: '90vh' }}>
      <div className="row flex-grow-1 align-items-center justify-content-center">
        <div className="col-md-4 col-sm-10 text-center">
        <img src="/RelvyAI_JustLogo.png" alt="Relvy Logo" className="img-fluid mb-4" style={{ maxWidth: '100px' }} />
        <h3 className="mb-3">Welcome to Relvy</h3>
          <div className="card p-3 shadow-sm mb-4">
            {loginMethod === 'google' &&
            <>
              <p className="mb-3" style={{ fontSize: '0.8rem' }}>Start using Relvy by signing in with your Google Account</p>
              <div className="d-flex justify-content-center align-items-center">
                <GoogleLogin
                  onSuccess={onSuccessfulGoogleLogin}
                  onError={() => {
                    console.log('Login Failed');
                  }}
                  render={({ onClick, disabled }) => (
                    <button
                      className="btn btn-light btn-md w-100 d-flex align-items-center justify-content-center"
                      onClick={onClick}
                      disabled={disabled}
                      style={{minHeight: '40px'}}
                    >
                      <img src="/google-logo.png" alt="Google logo" className="me-2" style={{width: '20px', height: '20px'}} />
                      Sign in with Google
                    </button>
                  )}
                />
              </div>
              <div className="mt-3">
                <button className="btn btn-link btn-sm" onClick={() => setLoginMethod('email')}>Sign in with email</button>
              </div>
            </>}
            {loginMethod === 'email' &&
            <>
              <Form onSubmit={handleEmailLogin} style={{ width: '75%' }}>
                <Form.Group className="mb-4">
                  <Col md={12} className="text-start">
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                    />
                  </Col>
                  <Col md={12} className="text-start">
                      <Form.Label>Password</Form.Label>
                      <Form.Control
                      type="password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      required
                      />
                  </Col>
                </Form.Group>
                <div className="d-flex justify-content-start mt-4">
                  <Button variant="primary" type="submit" className="custom-btn" disabled={isLoading}>
                    {isLoading ? (
                      <>
                        <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                        Loading...
                      </>
                    ) : (
                      'Sign in'
                    )}
                  </Button>
                </div>
              </Form>
              <div className="row mt-3">
                <div className="text-start">
                  <div><button className="btn btn-link btn-sm px-0" onClick={() => setLoginMethod('google')}>Sign in with Google</button></div>
                  <div><button className="btn btn-link btn-sm px-0" onClick={() => setLoginMethod('forgot')}>Forgot password?</button></div>
                  <div><button className="btn btn-link btn-sm px-0" onClick={() => setLoginMethod('register')}>Create an account</button></div>
                </div>
              </div>
            </>}
            {loginMethod === 'forgot' &&
            <>
              <Form onSubmit={handleForgotPassword} style={{ width: '75%' }}>
                <Form.Group className="mb-4">
                  <Col md={12} className="text-start">
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                    />
                  </Col>
                </Form.Group>
                <div className="d-flex justify-content-start mt-4">
                  <Button variant="primary" type="submit" className="custom-btn" disabled={isLoading}>
                    {isLoading ? (
                      <>
                        <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                        Loading...
                      </>
                    ) : (
                      'Get reset link'
                    )}
                  </Button>
                </div>
              </Form>
              <div className="row mt-3">
                <div className="text-start">
                  <div><button className="btn btn-link btn-sm px-0" onClick={() => setLoginMethod('google')}>Sign in with Google</button></div>
                  <div><button className="btn btn-link btn-sm px-0" onClick={() => setLoginMethod('email')}>Sign in with email</button></div>
                  <div><button className="btn btn-link btn-sm px-0" onClick={() => setLoginMethod('register')}>Create an account</button></div>
                </div>
              </div>
            </>}
            {loginMethod === 'register' &&
            <>
              <Form onSubmit={handleRegister} style={{ width: '75%' }}>
                <Form.Group className="mb-4">
                  <Col md={12} className="text-start">
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                    />
                  </Col>
                  <Col md={12} className="text-start">
                    <Form.Label>First Name</Form.Label>
                    <Form.Control
                    type="text"
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                    required
                    />
                  </Col>
                  <Col md={12} className="text-start">
                    <Form.Label>Last Name</Form.Label>
                    <Form.Control
                    type="text"
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                    required
                    />
                  </Col>
                  <Col md={12} className="text-start">
                      <Form.Label>Password</Form.Label>
                      <Form.Control
                      type="password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      required
                      />
                  </Col>
                </Form.Group>
                <div className="d-flex justify-content-start mt-4">
                  <Button variant="primary" type="submit" className="custom-btn" disabled={isLoading}>
                    {isLoading ? (
                      <>
                        <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                        Loading...
                      </>
                    ) : (
                      'Register'
                    )}
                  </Button>
                </div>
              </Form>
              <div className="row mt-3">
                <div className="text-start">
                  <div><button className="btn btn-link btn-sm px-0" onClick={() => setLoginMethod('google')}>Sign in with Google</button></div>
                  <div><button className="btn btn-link btn-sm px-0" onClick={() => setLoginMethod('email')}>Sign in with email</button></div>
                </div>
              </div>
            </>}
          {message && <div className="alert alert-info mt-3">{message}</div>}
          </div>
          <hr className="w-100 mx-auto mb-3" />
          <div className="explore-section">
            <h5 className="mb-3">Explore Relvy</h5>
            <div className="d-grid gap-2 col-8 mx-auto">
              <a href="https://share.hsforms.com/1R29gPNRURmKe6vLR_0Q9vQrhaij" className="btn btn-sm btn-outline-primary" target="_blank" rel="noreferrer">
                Self-host Relvy (Enterprise)
              </a>
              <a href="https://sandbox.relvy.ai" className="btn btn-sm btn-outline-secondary" target="_blank" rel="noreferrer">
                Try Sandbox Demo
              </a>
            </div>
          </div>
        </div>
      </div>
      <footer className="mt-auto py-3">
        <div className="text-center">
          <small>
            <a href="https://www.relvy.ai/privacy-policy" className="text-muted me-2" target="_blank" rel="noreferrer">Privacy Policy</a>
            |
            <a href="https://www.relvy.ai/relvy-tos" className="text-muted ms-2" target="_blank" rel="noreferrer">Terms of Service</a>
          </small>
        </div>
      </footer>
    </div>
  );
};

export default Landing;

