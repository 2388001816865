import { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';

const OktaRedirect = () => {
  const { orgId } = useParams();
  const location = useLocation();
  const [status, setStatus] = useState({
    isLoading: true,
    isError: false,
    message: 'Connecting to Okta...'
  });

  const connectOkta = async () => {
    try {
      const queryParams = new URLSearchParams(location.search);
      const code = queryParams.get('code');
      const state = queryParams.get('state');

      if (!code) {
        throw new Error('No authorization code provided');
      }

      const response = await fetch(`/api/sso/o/${orgId}/auth?code=${code}&state=${state}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
        },
      });

      const data = await response.json();

      if (!response.ok || data.error) {
        throw new Error(data.error || 'Failed to connect to Okta');
      }
      document.cookie = 'token=' + data.user.api_token + '; path=/; max-age=' + 60 * 60 * 24;
      window.location.href = '/';
    } catch (error) {
      setStatus({
        isLoading: false,
        isError: true,
        message: 'Failed to connect to Okta. Please try again later or reach out to us at hello@relvy.ai'
      });
    }
  };

  useEffect(() => {
    connectOkta();
  }, []);

  if (status.isLoading) {
    return (
      <div className="container text-center my-5">
        <div className="spinner-border text-primary mb-3" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
        <h4>{status.message}</h4>
      </div>
    );
  }

  if (status.isError) {
    return (
      <div className="container text-center my-5">
        <div className="text-danger mb-4">
          <i className="bi bi-exclamation-circle-fill fs-1"></i>
        </div>
        <h3 className="mb-3">Okta Connection Failed</h3>
        <p className="text-muted">{status.message}</p>
      </div>
    );
  }

  return null;
};

export default OktaRedirect;
