import { useState, useEffect } from 'react';
import { makeAuthenticatedRequest } from '../routes/utils';
import { formatDateTime } from '../routes/utils';
import { CollapsibleCard } from './issue_details/common';

const SystemSummaryView = (props) => {
    const [summaryData, setSummaryData] = useState(null);
    const [error, setError] = useState(null);



    useEffect(() => {
      const fetchSummaryData = async () => {
        try {
          const baseUrl = `/api/dashboard/v2/issues/summary_snapshot?client_system_id=${props.clientSystemId}&type=${props.summaryType}`;
          const url = props.reportId ? `${baseUrl}&report_id=${props.reportId}` : baseUrl;
          const response = await makeAuthenticatedRequest(url);
          if (!response.ok) throw new Error('Failed to fetch summary data');
          const data = await response.json();
          setSummaryData({
            insights: data.snapshot.summary_snapshot.insights,
            summary: data.snapshot.summary_snapshot.summary,
            last_updated: formatDateTime(data.created_at)
          });
        } catch (err) {
          setError(err.message);
        }
      };

      fetchSummaryData();
      const interval = setInterval(fetchSummaryData, 30000); // Refresh every 30 seconds

      return () => clearInterval(interval);
    }, [props.clientSystemId, props.reportId]);

    if (error) return null;//<div className="text-danger">Error: {error}</div>;
    if (!summaryData) return <div className="d-flex justify-content-center p-4"><div className="spinner-border" role="status" /></div>;

    return (
      <CollapsibleCard
        title={
          <div className="d-flex justify-content-between align-items-center w-100">
            {props.summaryType === "dashboard_summary" ? <span>System Health Summary</span> : <span>Overall Summary</span>}
          </div>
        }
        bodyClassName="font-size-085"
        defaultCollapsed={false}
      >
        <div className="mb-4">
          <p className="text-muted mb-3">{summaryData.summary}</p>
          <small className="text-muted">Updated {summaryData.last_updated}</small>
          <div className="d-flex flex-column gap-2">
            {summaryData.insights.map((insight, index) => (
              <div
                key={index}
                className="d-flex justify-content-between align-items-center ps-2"
                style={{ borderLeft: '3px solid #dee2e6' }}
              >
                <div>
                  <span className="fw-semibold">{insight.title}</span>: {insight.description}
                </div>
                <div>
                  {insight.issue_list.map((issueId) => (
                    <button
                      key={issueId}
                      onClick={() => props.onIssueSelect(parseInt(issueId, 10))}
                      className="badge bg-light text-primary border border-primary-subtle mx-1 px-2 py-1"
                      style={{
                        cursor: 'pointer',
                        transition: 'all 0.2s ease',
                        fontSize: '0.85rem',
                        textDecoration: 'none'
                      }}
                      onMouseOver={(e) => e.target.style.backgroundColor = '#e8f0fe'}
                      onMouseOut={(e) => e.target.style.backgroundColor = '#fff'}
                    >
                      #{issueId}
                    </button>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>
      </CollapsibleCard>
    );
  }

export default SystemSummaryView;