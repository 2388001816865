import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Form, Col } from 'react-bootstrap';

const SsoLogin = () => {
  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);
  const [orgId, setOrgId] = useState(queryParams.get('o'));

  const initiateLogin = async () => {
    const response = await fetch(`/api/sso/o/${orgId}/initiate`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
      },
    });
    if (!response.ok) {
      throw new Error('Failed to initiate login');
    }
    const responseJson = await response.json();
    window.location.href = responseJson['redirect_uri'];
  }

  return (
    <div className="container my-5">
      <Col md={4}>
        <Form.Label>Log In With SSO</Form.Label>
        <Form.Control type="text" placeholder="Relvy Organization ID" value={orgId} onChange={(e) => setOrgId(e.target.value)} />
        <button className="btn btn-primary mt-3" onClick={initiateLogin}>Log In</button>
      </Col>
    </div>
  );
};

export default SsoLogin;

