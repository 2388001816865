import { useEffect, useState } from 'react'

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  TimeScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import 'chartjs-adapter-date-fns';
import { Line, Bar } from 'react-chartjs-2';

ChartJS.register(
  TimeScale,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  BarElement
);


// Helper function to get color from index
const getColorFromIndex = (index) => {
  const hue = (index * 137.508) % 360;  // Golden angle approximation
  return `hsla(${hue}, 65%, 55%, 1)`;
};

const ScalarMetricView = (props) => {
  const columns = props.metricData?.data?.attributes?.columns;
  const selectedRows = props.selectedRows;
  if (!columns || columns.length < 1) return null;

  if (columns.length === 1) {
    const firstColumn = columns[0];
    const values = firstColumn.values;
    const unit = firstColumn.meta?.unit?.[0]?.short_name ||
                 firstColumn.meta?.unit?.[0]?.name || '';

    return (
      <div className="scalar-value-container p-3 bg-light rounded">
        <div className="d-flex">
          <pre className="m-0" style={{ color: getColorFromIndex(0) }}>
            {unit.length > 0 && <span>{unit}: {values}</span>}
            {unit.length === 0 && <span>{values}</span>}
          </pre>
        </div>
      </div>
    );
  }

  // Extract labels and values from columns
  const group_columns = columns.filter(col => col.type === 'group');
  var labels;
  if (group_columns.length > 0){
    labels = group_columns[0].values.map(val => Array.isArray(val) ? val[0] : val);
    for (let indx = 1; indx < group_columns.length; indx++) {
      for (let labelIndx = 0; labelIndx < labels.length; labelIndx++) {
        labels[labelIndx] = labels[labelIndx] + ', ' + group_columns[indx].values[labelIndx].map(val => Array.isArray(val) ? val[0] : val);
      }
    }
  } else {
    labels = columns[0].values.map(val => Array.isArray(val) ? val[0] : val);
  }
  const all_values = columns.filter(col => col.type === 'number').map(col => col.values);
  const legends = columns.filter(col => col.type === 'number').map(col => col.name);
  const unit = columns.find(col => col.type === 'number')?.meta?.unit?.[0]?.short_name || '';

  // Create chart data
  const data = {
    labels: labels,
    datasets: all_values.map((values, index) => {
      return {
        data: values,
        backgroundColor: getColorFromIndex(index),
        borderColor: getColorFromIndex(index),
        borderWidth: 1,
        label: legends[index]
      };
    })
  };

  const options = {
    indexAxis: 'y',
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: true,
        position: 'top',
      },
      title: {
        display: true,
        text: unit ? `Values (${unit})` : 'Values'
      },
      tooltip: {
        callbacks: {
          label: (context) => `${context.parsed.x} ${unit}`,
          title: (tooltipItems) => tooltipItems[0].label
        }
      }
    },
    scales: {
      x: {
        beginAtZero: true,
        grid: {
          color: '#E5E5E5'
        },
        title: {
          display: true,
          text: unit
        }
      },
      y: {
        ticks: {
          autoSkip: false,
          maxRotation: 0,
          minRotation: 0
        },
        grid: {
          color: '#E5E5E5'
        }
      }
    }
  };

  // Set a fixed container height and let the chart grow inside it
  return (
    <div style={{ height: '500px', position: 'relative' }}>
      <div style={{
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        overflowY: 'scroll'
      }}>
        <div style={{
          height: `${Math.max(800, labels.length * 20)}px`,
          minHeight: '100%'
        }}>
          <Bar options={options} data={data} />
        </div>
      </div>
    </div>
  );
};


const LineMetricView = ({item, selectedTimeSeries}) => {
  const [selectedSeries, setSelectedSeries] = useState([]);
  const [key, setKey] = useState(0);
  const series = item.data?.data?.attributes?.series;
  const times = item.data?.data?.attributes?.times;
  const values = item.data?.data?.attributes?.values;

  useEffect(() => {
    // Force chart re-render when component mounts
    setKey(prev => prev + 1);

    // Select series indices that match selectedTimeSeries group tags
    if (series && series.length > 0) {
      if (selectedTimeSeries && selectedTimeSeries.length > 0) {
        const selectedIndices = series.reduce((indices, seriesItem, index) => {
          if (selectedTimeSeries.some(selectedTags =>
            JSON.stringify(seriesItem.group_tags) === JSON.stringify(selectedTags)
          )) {
            indices.push(index);
          }
          return indices;
        }, []);
        setSelectedSeries(selectedIndices);
      } else {
        // If no selectedTimeSeries, show first 10 series
        setSelectedSeries(Array.from({ length: Math.min(10, series.length) }, (_, i) => i));
      }
    }
  }, [series, selectedTimeSeries]);

  if (!series || !times || !values) return null;
  if (series.length !== values.length) return null;
  if (series.length === 0) return null;

  const getTitle = (series) => {
    for (const seriesItem of series) {
      if (seriesItem.unit && seriesItem.unit.length > 0) {
        return seriesItem.unit[0]?.name || '';
      }
    }
    return null;
  }

  const getTimeseriesName = (seriesItem) => {
    var namePrefix = ''
    if (seriesItem.query_index !== undefined){
      if(item.formulas && item.formulas.length > 0){
        namePrefix = (item.formulas[seriesItem.query_index].alias || item.formulas[seriesItem.query_index].formula) + ': ';
      } else if (item.queries && item.queries.length > 0){
        namePrefix = item.queries[seriesItem.query_index].name + ": ";
      }
    }
    return namePrefix + seriesItem.group_tags.join(', ')
  };

  // Create the chart data using only selected series
  const data = {
    datasets: selectedSeries.map(selectedIndex => ({
      label: getTimeseriesName(series[selectedIndex]),
      data: times.map((time, timeIndex) => ({
        x: new Date(time),
        y: values[selectedIndex][timeIndex]
      })),
      borderColor: getColorFromIndex(selectedIndex),
      backgroundColor: getColorFromIndex(selectedIndex),
      borderWidth: 1.5,
      pointRadius: 0,
      pointHoverRadius: 2,
      tension: 0.1
    }))
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: false,
        text: 'Metric Values Over Time'
      }
    },
    scales: {
      x: {
        type: 'time',
        time: {
          unit: 'minute'
        },
        title: {
          display: true,
          text: 'Time'
        }
      },
      y: {
        title: {
          display: true,
          text: getTitle(series) || 'Value'
        }
      }
    }
  };

  // Add series selection UI
  const handleSeriesToggle = (index) => {
    setSelectedSeries(prev =>
      prev.includes(index)
        ? prev.filter(i => i !== index)
        : [...prev, index]
    );
  };

  // Add this new function to handle toggling all series
  const handleToggleAll = () => {
    if (selectedSeries.length === series.length) {
      // If all series are selected, deselect all
      setSelectedSeries([]);
    } else {
      // Otherwise, select all series
      setSelectedSeries(Array.from({ length: series.length }, (_, i) => i));
    }
  };

  return (
    <div className="chart-container" style={{
      maxHeight: '600px',
      maxWidth: '100%',
      margin: '0 auto'
    }}>
      {series.length > 1 && <div className="series-selector mb-3" style={{
        maxHeight: '150px',
        overflowY: 'auto',
        border: '1px solid #dee2e6',
        borderRadius: '4px',
        padding: '10px'
      }}>
        <div className="d-flex justify-content-end mb-2">
          <button
            className="btn btn-sm btn-outline-secondary"
            onClick={handleToggleAll}
          >
            {selectedSeries.length === series.length ? "Deselect All" : "Select All"}
          </button>
        </div>
        <div style={{ display: 'grid', gridTemplateColumns: '1fr' }}>
          {series.map((seriesItem, index) => (
            <label
              key={index}
              style={{
                display: 'flex',
                alignItems: 'center',
                padding: '4px 8px',
                cursor: 'pointer',
                backgroundColor: selectedSeries.includes(index) ? '#f8f9fa' : 'transparent',
                borderBottom: '1px solid #eee'
              }}
            >
              <input
                type="checkbox"
                checked={selectedSeries.includes(index)}
                onChange={() => handleSeriesToggle(index)}
                className="me-2"
              />
              <div
                style={{
                  width: '24px',
                  height: '12px',
                  backgroundColor: getColorFromIndex(index),
                  marginRight: '8px',
                  borderRadius: '2px'
                }}
              />
              <span style={{
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis'
              }}>
                {getTimeseriesName(seriesItem)}
              </span>
            </label>
          ))}
        </div>
      </div>}
      <div style={{ height: '400px' }}>
        {key > 0 && <Line key={key} options={{
          ...options,
          animation: false,
          maintainAspectRatio: false
        }} data={data} />}
      </div>
    </div>
  );
};

export const WidgetView = (props) => {
  const widgetData = props.widgetData || [];

  if (!widgetData.length) return null;

  return (
    <div>
      <h6 className="mb-2 text-muted font-size-085">Data</h6>
      {widgetData.map((item, index) => (
        <div key={index}>
          {item.queries && <>
            <p className="text-muted mb-2 font-size-085">Queries</p>
            <pre className="bg-light p-3 rounded" style={{ color: '#333' }}>
              {Array.isArray(item.queries)
                ? item.queries.map(q => q.query).join('\n')
                : item.queries.query
                  ? Array.isArray(item.queries.query)
                    ? item.queries.query.join('\n')
                    : item.queries.query
                  : JSON.stringify(item.queries, null, 2)
              }
            </pre>
          </>}
          {item.response_format === "timeseries" && (
            <LineMetricView item={item} selectedTimeSeries={props.selectedTimeSeries} />
          )}
          {item.response_format === "scalar" && (
            <ScalarMetricView metricData={item.data} selectedRows={props.selectedRows}/>
          )}
        </div>
      ))}
    </div>
  );
}

export const ApmMetricsView = (props) => {
  return (
    <div>
      <h6 className="mb-2 text-muted font-size-085">Data</h6>
      <div>
        <p className="text-muted mb-2 font-size-085">Queries</p>
        <pre className="bg-light p-3 rounded" style={{ color: '#333' }}>{props.query}</pre>
        <LineMetricView item={{data: props.tsData}} />
      </div>
    </div>
  );
}

export const AlertingMetricView = (props) => {
  return (
    <div>
      <h6 className="mb-2 text-muted font-size-085">Data</h6>
      <div>
        <p className="text-muted mb-2 font-size-085">Queries</p>
        <pre className="bg-light p-3 rounded" style={{ color: '#333' }}>{props.query}</pre>
        <LineMetricView item={{data: props.tsData}} selectedTimeSeries={props.selectedTimeSeries} />
      </div>
    </div>
  );
}
