import { createRef, useEffect, useMemo, useState } from 'react';
import { Accordion, Container, Row, Col, Card, Spinner, Tabs, Tab, Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import ReactDiffViewer from 'react-diff-viewer-continued';

// import {CustomPlaceholder, ParagraphPlaceholder} from '../../routes/issue_details_components';
// import {Attachment, AttachmentModal, Breadcrumb, CommentsCard} from '../../routes/issue_details_components';
import {
  createDisplayStepFromLogAnalysisData, createDisplayStepFromMetricsAnalysisData, createDisplayStepFromWidgetAnalysisData,
  createDisplayStepFromApmSpanAnalysisData, createDisplayStepFromApmMetricsAnalysisData
} from './common';

import '../demo.css';
import '../css/issue_details.css';

import {makeAuthenticatedRequest} from '../../routes/utils';

import {AnalysisDataViewV2} from './issue_details_v2';
import {AnalysisDataView} from './issue_details_v1';
import {SimilarIssues, CollapsibleCard, MetricAnalysisStepView, LogAnalysisStepView, FeedbackButtons} from './common';
import {GithubCommitInfo} from './code_commit_view';
import {fetchIssueAnalysisStep} from './common';

const IssueDataView = (props) => {
  const [isExpanded, setIsExpanded] = useState(false);

  // Sanitize and format description if needed
  const createMarkup = (htmlContent) => {
    // You could add additional sanitization here if needed
    return { __html: htmlContent || '' };
  };

  return (
    <div>

      <CollapsibleCard title={<strong>Details</strong>}>
        <Card.Text className="font-size-085">{props.issue.summary || '...'}</Card.Text>
        {props.issue.description && (<>
          {props.issue.description.includes('<p>') && props.issue.description.includes('<h6>') ? (
            <CollapsibleCard defaultCollapsed={true} title={<strong>Alerts</strong>}>
              <div
                dangerouslySetInnerHTML={createMarkup(props.issue.description)}
                className="description-content font-size-085"
              />
            </CollapsibleCard>) : (
              <div
                dangerouslySetInnerHTML={createMarkup(props.issue.description)}
                className="description-content font-size-085"
              />
            )}
        </>)}
      </CollapsibleCard>
    </div>
  );
}

const AnalysisTab = ({ issue, analysisData, onRunAnalysis, onUserPrompt, onInterrupt, selectIssueFromIdHandler }) => (
  analysisData ? (
    <div>
      <IssueDataView issue={issue} />
      {analysisData.responseFormat === 'v1' && <AnalysisDataView issue={issue} analysisData={analysisData} onRunAnalysis={onRunAnalysis} onUserPrompt={onUserPrompt} onInterrupt={onInterrupt} selectIssueFromIdHandler={selectIssueFromIdHandler} />}
      {analysisData.responseFormat === 'v2' && <AnalysisDataViewV2 issue={issue} analysisData={analysisData} onRunAnalysis={onRunAnalysis} onUserPrompt={onUserPrompt} onInterrupt={onInterrupt} selectIssueFromIdHandler={selectIssueFromIdHandler} />}
    </div>
  ) : (
    <div className="text-center py-4">
      <Spinner animation="border" role="status" variant="purple">
        <span className="visually-hidden">Loading analysis...</span>
      </Spinner>
    </div>
  )
);

const MainContent = ({ issue, analysisData, onRunAnalysis, onUserPrompt, onInterrupt, selectIssueFromIdHandler }) => {
  return (
    <div>
      <AnalysisTab issue={issue} analysisData={analysisData} onRunAnalysis={onRunAnalysis} onUserPrompt={onUserPrompt} onInterrupt={onInterrupt} selectIssueFromIdHandler={selectIssueFromIdHandler} />
    </div>
  );
};


const IssueDetailsPage = (props) => {
  const [issueData, setIssueData] = useState({
    summary: props.item.summary,
    details: props.item.description,
    relevant_files: null,
    clientSystemId: props.clientSystemId,
  });
  const [analysisData, setAnalysisData] = useState(null);

  const [failureMessage, setFailureMessage] = useState(null);
  const [pollerId, setPollerId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [numPollRequests, setNumPollRequests] = useState(0);

  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || '';

  const processIssueData = (data) => {
    setIssueData((prevData) => ({
      ...prevData,
      ...data,
    }));

    processAnalysisData(data['analysis_results']);
  };

  const fetchIssueData = async (issueId) => {

    if (numPollRequests > 100) {
      return;
    }
    setNumPollRequests(numPollRequests + 1);
    const url = `/api/dashboard/v2/issues/${issueId}?expand_all=false`;

    const options = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
      },
    };

    let response = await makeAuthenticatedRequest(url, options);
    if (response.ok) {
      processIssueData(await response.json());
    } else {
      setFailureMessage("Failed to fetch data");
    }
  };

  const clearPollerIfExists = () => {
    clearInterval(pollerId);
  };

  useEffect(() => {
    clearPollerIfExists();
    setNumPollRequests(0);
    fetchIssueData(props.item.id);
    let newPollerId = setInterval(() => {
      fetchIssueData(props.item.id);
    }, 2000);
    setPollerId(newPollerId);

    return () => {
      clearInterval(newPollerId);
    }
  }, [props.item.id]);


  const getReadableNextStep = (nextStep, params) => {
    if (nextStep === 'request_logs') {
      return "Request logs from " + params["service"];
    } else if (nextStep === 'request_metrics') {
      return "Request metrics from " + params["service"] + ' - ' + params["metric_name"];
    } else if (nextStep === 'escalate') {
      return "Escalate to " + params['to'];
    } else {
      return nextStep;
    }
  }

  useEffect(() => {
    if (!analysisData){
      clearPollerIfExists();
      return;
    }

    if (analysisData && !analysisData.stillRunning) {
      clearPollerIfExists();
      props.refreshCallback(false);
    }
  }, [analysisData]);


  const processAnalysisData = async (data) => {
    if (!data){
      setAnalysisData(null);
      return;
    }

    if (data['response_format'] ==! 'v2') {
      let expandedAnalysisHistory = []
      for (var stepIndx=0; stepIndx < data["analysis_history"].length; stepIndx++) {
        let expandedStep = await fetchIssueAnalysisStep(props.item.id, data["analysis_history"][stepIndx].id);
        if (expandedStep) {
          expandedAnalysisHistory.push(expandedStep);
        }
      }
      data["analysis_history"] = expandedAnalysisHistory;
    }

    let output = ('response_format' in data && data['response_format'] === 'v2') ? handleModularizedOutput(data) : handleLegacyOutput(data);
    setAnalysisData(output);
  };

  const handleModularizedOutput = (data) => {
    var output = {
      'responseFormat': 'v2',
      'relatedIssueData': data['related_issue_data'],
      'githubCommitInfo': data['github_commit_info'],
      'initialAssessment': data['initial_assessment'],
      'relatedRunbooks': data['related_runbook_ids'],
    };

    let finalResponse = data["final_response"];
    output['stillRunning'] = (data["state"] === "IN_PROGRESS" || data["state"] === "INTERRUPTING" || data["state"] === "NOT_STARTED");
    output['state'] = data["state"];
    output['progressData'] = data?.progress_data;

    let combinedResponse = data['combined_response'];
    output['combinedResponse'] = combinedResponse;

    if (finalResponse && "next_step" in finalResponse) {
      output['nextStep'] = getReadableNextStep(finalResponse["next_step"], finalResponse["params"]);
      output['nextStepReason'] = finalResponse["reason"];
      output['rootCause'] = finalResponse["possible_root_cause_so_far"];
      output['readableRootCause'] = finalResponse["readable_rca"];
      output['githubCommitInfo'] = finalResponse["github_commit_info"];
    }

    if ("combined_response" in data) {
      output['combinedResponse'] = data["combined_response"];
    }

    // Assume the steps are in ascending order of time/id
    for (var stepIndx=0; stepIndx < data["analysis_history"].length; stepIndx++) {
      let stepId = data["analysis_history"][stepIndx].id;

      let moduleInvocationId = data["analysis_history"][stepIndx].module_invocation_id;
      let moduleOutput = data["module_outputs"].find((output) => output["id"] === moduleInvocationId);
      if (!moduleOutput) {
        console.log("Module output not found for invocation id: " + moduleInvocationId);
        continue;
      }

      if (!('displaySteps' in moduleOutput)) {
        moduleOutput['displaySteps'] = [];
      }
      moduleOutput['displaySteps'].push({"stepId": stepId});

    }

    output['moduleOutputs'] = data["module_outputs"];
    output['feedbackScore'] = data['feedback_score'];
    return output;
  };

  const handleLegacyOutput = (data) => {
    var output = {
      'responseFormat': 'v1',
      'identifiersToAskFor': data['identifiers_to_ask_for'],
      'relatedIssueData': data['related_issue_data'],
      'githubCommitInfo': data['github_commit_info'],
      'initialAssessment': data['initial_assessment'],
    };

    let finalResponse = data["final_response"];
    output['stillRunning'] = (data["state"] === "IN_PROGRESS" || data["state"] === "INTERRUPTING" || data["state"] === "NOT_STARTED");
    output['state'] = data["state"];
    if (finalResponse && "next_step" in finalResponse) {
      output['nextStep'] = getReadableNextStep(finalResponse["next_step"], finalResponse["params"]);
      output['nextStepReason'] = finalResponse["reason"];
      output['rootCause'] = finalResponse["possible_root_cause_so_far"];
      output['readableRootCause'] = finalResponse["readable_rca"];
      output['githubCommitInfo'] = finalResponse["github_commit_info"];
      //output['stillRunning'] = false;
    }

    output['possibleRootCauseSoFar'] = null;

    var displayAnalysisSteps = [];
    var displayNextStep = "";
    var displayNextStepReason = "";
    for (var stepIndx=0; stepIndx < data["analysis_history"].length; stepIndx++) {
      let analysisStepType = data["analysis_history"][stepIndx].step_type;
      let analysisStepData = data["analysis_history"][stepIndx].step_data;
      //console.log(analysisStepType);
      if (analysisStepType === "next_step") {
        output['possibleRootCauseSoFar'] = analysisStepData["possible_root_cause_so_far"];
        output['readableRootCauseSoFar'] = analysisStepData["readable_rca"];
        if (analysisStepData["next_step"] === "request_logs") {
          displayNextStep = "Process logs from " + analysisStepData["params"]["service"];
        } else if (analysisStepData["next_step"] === "request_metrics") {
          displayNextStep = "Process metrics from " + analysisStepData["params"]["service"] + ' - ' + analysisStepData["params"]["metric_name"];
        } else {
          displayNextStep = analysisStepData["next_step"];
        }
        displayNextStepReason = analysisStepData["reason"];
      } else if (analysisStepType === "observation") {
        if (analysisStepData["observation_type"] === 'log_analysis'){
          displayAnalysisSteps.push(createDisplayStepFromLogAnalysisData(
            analysisStepData, displayNextStep, displayNextStepReason));
        } else if (analysisStepData["observation_type"] === 'metrics_analysis'){
          displayAnalysisSteps.push(createDisplayStepFromMetricsAnalysisData(
            analysisStepData, displayNextStep, displayNextStepReason));
        } else {
          console.log("Unknown observation type: " + analysisStepData["observation_type"]);
        }
      } else if (analysisStepType === "user_input") {
        displayAnalysisSteps.push({
          'step_type': 'user_input',
          'message': analysisStepData['message'],
        });
      }
    }

    if (output['stillRunning'] && data["analysis_history"].length > 0 && data['analysis_history'][data["analysis_history"].length - 1].step_type === 'next_step') {
      displayAnalysisSteps.push({
        'step_type': 'ongoing',
        'currentNextStep': displayNextStep,
        'currentNextStepReason': displayNextStepReason,
      });
    }

    output['analysisSteps'] = displayAnalysisSteps;
    output['feedbackScore'] = data['feedback_score'];
    return output;
  };

  const onInterrupt = () => {
    return makeAuthenticatedRequest(`/api/dashboard/v2/issues/${props.item.id}/interrupt`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({}),
    }).then((response) => {
      if (response.ok) {
        fetchIssueData(props.item.id);
        return Promise.resolve();
      } else {
        console.log("Failed to interrupt analysis");
        return Promise.reject("Failed to interrupt analysis");
      }
    });
  };

  const onRunAnalysis = () => {
    return makeAuthenticatedRequest(`/api/dashboard/v2/issues/${props.item.id}/analyze`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    }).then((response) => {
      if (response.ok) {
        fetchIssueData(props.item.id);
        // Restart the polling mechanism
        setNumPollRequests(0);
        let newPollerId = setInterval(() => {
          fetchIssueData(props.item.id);
        }, 2000);
        setPollerId(newPollerId);
      } else {
        console.error("Failed to run analysis");
        return Promise.reject("Failed to run analysis");
      }
    });
  };

  const onUserPrompt = (message) => {
    // clearPollerIfExists();

    // TODO show spinner or update UI temporarily
    if (analysisData && analysisData?.responseFormat !== 'v2') {
      let updatedAnalysisData = {...analysisData};
      updatedAnalysisData.analysisSteps.push({
        'step_type': 'user_input',
        'message': message,
      });
      setAnalysisData(updatedAnalysisData);
    } else {
      let userInputModule = {
        'module_name': 'user_input',
        'analysis_data': {
          'message': message,
        },
      };
      analysisData.moduleOutputs.push(userInputModule);
    }

    return makeAuthenticatedRequest(`/api/dashboard/v2/issues/${props.item.id}/prompt`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        message: message,
      }),
    }).then((response) => {
      if (response.ok) {
        fetchIssueData(props.item.id);
        // Restart the polling mechanism
        setNumPollRequests(0);
        let newPollerId = setInterval(() => {
          fetchIssueData(props.item.id);
        }, 2000);
        setPollerId(newPollerId);
      } else {
        console.log("Failed to submit message");
        throw new Error("Failed to submit message");
      }
    });
  };

  return (
    <MainContent issue={issueData} analysisData={analysisData} onRunAnalysis={onRunAnalysis} onUserPrompt={onUserPrompt} onInterrupt={onInterrupt} selectIssueFromIdHandler={props.selectIssueFromIdHandler} />
  );
};

export default IssueDetailsPage;
