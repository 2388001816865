import React, { useMemo } from 'react';

export const ApmSpanView = (props) => {
  const MAX_WIDTH = 100; // percentage
  const maxDuration = Math.max(...props.spanTree.map(span => span.duration));

  // Generate color map based on unique resource types in the data
  const getResourceType = (resourceName) => {
    if (resourceName.includes(' /')) {
      return resourceName.split(' ')[0]; // GET, POST, PUT, etc.
    }
    return resourceName.split('.')[0]; // First part of the resource name
  };

  // Create color map for unique resource types
  const colorMap = useMemo(() => {
    const types = new Set();
    const traverseSpans = (span) => {
      types.add(getResourceType(span.resource_name));
      span.children.forEach(traverseSpans);
    };
    props.spanTree.forEach(traverseSpans);

    // Professional color palette (avoiding red and yellow)
    const colors = [
      '#3B82F6', // blue-500
      '#10B981', // emerald-500
      '#8B5CF6', // violet-500
      '#6366F1', // indigo-500
      '#14B8A6', // teal-500
      '#06B6D4', // cyan-500
      '#8B5CF6', // violet-500
      '#EC4899', // pink-500
      '#84CC16', // lime-500
    ];

    const colorMapping = {};
    Array.from(types).forEach((type, index) => {
      colorMapping[type] = colors[index % colors.length];
    });
    return colorMapping;
  }, [props.spanTree]);

  const SpanView = ({span}) => {
    const widthPercentage = (span.duration / maxDuration) * MAX_WIDTH;
    const resourceType = getResourceType(span.resource_name);

    // Determine color based on status and duration
    let backgroundColor;
    if (span.status === 'error') {
      backgroundColor = '#DC2626'; // red-600
    } else if (span.duration / maxDuration > 0.7) { // More than 70% of max duration
      backgroundColor = '#EF4444'; // red-500
    } else {
      backgroundColor = colorMap[resourceType];
    }

    const [expanded, setExpanded] = React.useState(false);

    return (
      <div className="mb-1">
        <div className="d-flex align-items-center gap-2">
          <div 
            className="rounded"
            dataToggle="tooltip"
            title={span.resource_name}
            dataPlacement="top"
            style={{
              width: `${widthPercentage}%`,
              minWidth: '50px',
              height: '20px',
              backgroundColor,
              position: 'relative',
              transition: 'all 0.2s',
              opacity: 0.9,
              cursor: 'pointer',
            }}
            onClick={() => setExpanded(!expanded)}
          >
            {/* Span info overlay */}
            <div 
              className="position-absolute top-0 start-0 h-100 w-100 d-flex align-items-center px-2"
              style={{ 
                color: 'white',
                fontSize: '0.75rem',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis'
              }}
            >
              <span className="text-truncate">
                {span.resource_name}
                {span.status === 'error'}
                {span.duration / maxDuration > 0.7}
              </span>
            </div>
          </div>

          <span className="badge bg-light text-dark" style={{ fontSize: '0.75rem' }}>
            {span.duration.toFixed(3)}s
          </span>
        </div>
        {expanded && (
        <div>
          <div className="text-muted small mt-1">{span.resource_name}</div>
          {span.status === 'error' && <span className="badge bg-danger mt-1">Error: {JSON.stringify(span.error)}</span>}
        </div>)}

        {/* Child spans */}
        {span.children.length > 0 && (
          <div className='ms-4 mt-1'>
            {span.children.map((child, index) => (
              <div key={index}>
                <SpanView span={child}/>
              </div>
            ))}
          </div>
        )}
      </div>
    );
  };

  // Legend component
  const Legend = () => (
    <div className="mb-3 d-flex flex-wrap gap-2">
      {Object.entries(colorMap).map(([type, color]) => (
        <div key={type} className="d-flex align-items-center">
          <div 
            className="rounded me-1" 
            style={{ 
              width: '12px', 
              height: '12px', 
              backgroundColor: color 
            }} 
          />
          <small className="text-muted">{type}</small>
        </div>
      ))}
    </div>
  );

  return (
    <div className="bg-light p-3 rounded">
      <h6 className="mb-3 text-muted">Trace Details</h6>
      <div className="mb-4">
        <p className="text-muted mb-2 small">Query</p>
        <pre className="bg-white p-3 rounded" style={{ color: '#333' }}>
          {props.query}
        </pre>
      </div>
      <div>
        <p className="text-muted mb-2 small">Spans</p>
        <Legend />
        {/* Timeline header */}
        <div className="d-flex justify-content-between mb-2 px-2">
          <span className="small">0s</span>
          <span className="small">{(maxDuration).toFixed(3)}s</span>
        </div>
        {/* Timeline grid */}
        <div className="position-relative mb-2">
          <div className="position-absolute w-100 h-100" style={{
            backgroundImage: 'repeating-linear-gradient(90deg, #dee2e6 0px, #dee2e6 1px, transparent 1px, transparent 20%)',
            opacity: 0.5
          }}/>
        </div>
        {props.spanTree.map((span, index) => (
          <div key={index}>
            <SpanView span={span} />
          </div>
        ))}
      </div>
    </div>
  );
}
